import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';
import {
  ActionButtons,
  FormLabel,
  Row,
} from '@as_core/forms/FormStyles';
import Button from '@as_core/account/fields/Button';
import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';
import dayjs from 'dayjs';

type PropsT = {
  expires: string;
  handleUpdate?: (expires: any) => void;
  handleCancel?: () => void;
}

export const UpdateExpiration = (props:PropsT) => {
  const { handleUpdate} = props;
  const [expires, setExpires] = useState(dayjs(props?.expires));
  const [dateChanged, setDateChanged] = useState<boolean>(false);

  const _handleUpdates = (value:any) => {
    console.log('_handleUpdates', value);
    setDateChanged(true);
    setExpires(value);
  }

  const handleCancel = () => {
    console.log('Cancel Clicked', props?.handleCancel);
    props?.handleCancel && props.handleCancel();
  }

  console.log('UpdateExpiration | expires', expires);

  return (
    <Container key={'create-new-coupon'}>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>Expiration Date:</FormLabel>
        <FlexItem width={'200px'} height={'50px'}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
            <DatePicker
              label={'Expiration Date'}
              format={'YYYY-MM-DD'}
              value={expires}
              onChange={(v) => _handleUpdates(v)}
            />
          </LocalizationProvider>
        </FlexItem>
      </Row>
      <ActionButtons>
        <Button type='cancel' width={100} label={'Cancel'} onClick={() => handleCancel()} />
        <Button
          type='submit'
          width={100}
          label={'Update Coupon'}
          disabled={!dateChanged}
          onClick={!dateChanged ? ()=>{} : () => handleUpdate(expires)}
        />
      </ActionButtons>
    </Container>
  );
};

export default UpdateExpiration;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;