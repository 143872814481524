import { StyleContext } from '@theme/AppStyles';
import { useContext } from 'react';

const BugsClosedIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;

  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={size}
      height={size}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <path fill={colorHighlight} d="M103.4,359.4c0,84.2,68.5,152.6,152.6,152.6s152.6-68.5,152.6-152.6V187.1c0-13.6-11-24.6-24.6-24.6h-14.8H128
	c-13.6,0-24.6,11-24.6,24.6L103.4,359.4z M270.8,192h108.3v167.4c0,67.9-55.2,123.1-123.1,123.1s-123.1-55.2-123.1-123.1V192h108.3
	H270.8z"/>
      <path fill={color} d="M408.7,230.2c44.6-7.1,78.8-45.8,78.8-92.4v-19.7c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8v19.7
	c0,30.2-21,55.6-49.2,62.3V230.2z"/>
      <path fill={color} d="M172.3,108.3c0-19,15.5-34.5,34.5-34.5h98.5c19,0,34.5,15.5,34.5,34.5v54.2h29.4v-54.2c0-31.1-22.3-57-51.7-62.8l11-24.7
	c3.3-7.5,0-16.2-7.5-19.5s-16.2,0-19.5,7.5l-15.8,35.5h-59.6L210.4,8.8c-3.3-7.5-12-10.8-19.5-7.5s-10.8,12-7.5,19.5l11,24.7
	c-29.4,5.8-51.7,31.7-51.7,62.8v54.2h29.6V108.3z"/>
      <path fill={color} d="M103.4,321.2c-44.6,7.1-78.8,45.8-78.8,92.4v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7
	c0-30.2,21-55.6,49.2-62.3V321.2z"/>
      <path fill={color} d="M103.4,260.9H14.8c-8.2,0-14.8,6.6-14.8,14.8c0,8.2,6.6,14.8,14.8,14.8h88.6V260.9z"/>
      <path fill={color} d="M103.4,200.1c-28.2-6.7-49.2-32.1-49.2-62.3v-19.7c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8v19.7
	c0,46.6,34.2,85.3,78.8,92.4V200.1z"/>
      <path fill={color} d="M408.7,321.2v30.1c28.2,6.7,49.2,32.1,49.2,62.3v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7
	C487.4,367,453.2,328.3,408.7,321.2z"/>
      <path fill={color} d="M497.2,260.9h-88.5v29.6h88.5c8.2,0,14.8-6.6,14.8-14.8C512,267.5,505.4,260.9,497.2,260.9z"/>
      <path fill={colorHighlight} stroke={color} strokeWidth={15} d="M473.3,447.4l-19.3,19.3c-16,16-41.9,16-57.8,0L38.7,109c-16-16-16-41.9,0-57.8l19.3-19.3c16-16,41.9-16,57.8,0
	l357.6,357.6C489.3,405.5,489.3,431.4,473.3,447.4z"/>
    </svg>
  );
};

export default BugsClosedIcon;
