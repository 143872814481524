import { useState } from 'react';
import { BugsReportT } from '@as_core/bugsReporting/bugs.types';
import {
  BasicTextArea,
  ActionButtons,
  FormLabel,
  Row,
} from '@as_core/forms/FormStyles';
import Button from '@as_core/account/fields/Button';
import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';

type CloseReportUpdatesT = {
  resolved: string,
  'resolution.comments': string,
}


type PropsT = {
  report: BugsReportT
  handleUpdate?: (uuid: string, updates: any) => void;
  handleCancel?: () => void;
}

export const CloseReport = (props:PropsT) => {
  const { report, handleUpdate} = props;
  const [updates, setUpdates] = useState<CloseReportUpdatesT>({
    resolved: 'true',
    'resolution.comments': ''
  });

  const _handleUpdates = (field:string, value:string) => {
    console.log(field, value);
    setUpdates((prev) => ({...prev, [field]: value }));
  }

  const handleCancel = () => {
    console.log('Cancel Clicked', props?.handleCancel);
    props?.handleCancel && props.handleCancel();
  }

  console.log('Acknowledge | updates', updates);
  const disabled = Object.keys(updates).length < 1;

  return (
    <Container key={'acknowledge-bug-report-form'}>
      <Row width='350px' align={'flex-start'}>
        <FormLabel width={150}>Resolution Comments:</FormLabel>
        <FlexItem>
          <BasicTextArea
            id='comments'
            width={250}
            height={100}
            name='ref'
            placeholder='Enter Any Comments ...'
            value={updates['resolution.comments']}
            onChange={(e) => _handleUpdates('resolution.comments', e.target.value)}
          />
        </FlexItem>
      </Row>
      <ActionButtons>
        <Button type='cancel' width={110} label={'Cancel'} onClick={() => handleCancel()} />
        <Button
          type='submit'
          width={100}
          label={'Set Issue As Resolved'}
          disabled={disabled}
          onClick={disabled ? ()=>{} : () => handleUpdate(report?.uuid, updates)}
        />
      </ActionButtons>
    </Container>
  );
};

export default CloseReport;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;