import SciScore from '@components/icons/IconSciscore';
import Icon3RnD from '@components/icons/Icon3RnD';
import GBGLogo from '@components/icons/IconGBGLogo';
import IconHome from '@components/icons/IconHome';
import IconBugs from '@components/icons/IconBugs';
import CustomCurationIcon from '@components/icons/customCuration.icon';
import CouponsIcon from '@as_core/icons/coupons.icon';

export const asedaCards = [
  {
    title: 'Aseda Sciences Website',
    authorizedOnly: false,
    newTab: true,
    icon: (
      <img
        src={'/img/AsedaSciences_logo.svg'}
        alt={'AsedaSciences'}
        width={64}
      />
    ),
    link: 'https://asedasciences.com',
    description: 'Look at the main AsedaSciences website for more information',
  },
  {
    title: '3RnD Application',
    authorizedOnly: false,
    newTab: true,
    icon: <Icon3RnD size={64} />,
    link: 'https://app-prd.thewarriors3.com',
    description: 'High-performance data analysis and visualization',
  },
  {
    id: 'AS_SciScore',
    title: 'SciScore',
    authorizedOnly: true,
    newTab: true,
    icon: <SciScore size={64} />,
    link: 'https://sciscore.asedasciences.com',
    description: 'Score methods section of a manuscript before submission',
  },
];

export const toolsCards = [
  {
    title: 'Bug Reports Handling',
    authorizedOnly: true,
    newTab: false,
    icon: <IconBugs size={64} />,
    link: '/bugs/new',
    description: 'Bug Reports Handling For Developers and Product Managers',
  },
  {
    title: 'Coupon Administration',
    authorizedOnly: true,
    newTab: false,
    icon: <CouponsIcon size={64} />,
    link: '/coupons',
    description: 'Handle the various account coupons across the various AsedaSciences ecosystem.',
  },
  {
    title: 'Compound Annotations Curation',
    authorizedOnly: true,
    newTab: false,
    icon: <CustomCurationIcon size={64} />,
    link: '/curation',
    description: 'coming soon',
  },
  {
    title: 'GBG Consulting',
    authorizedOnly: false,
    newTab: false,
    icon: <GBGLogo size={64} />,
    link: '/gbg',
    description: 'Under Development',
  },
  {
    title: 'Chemporium',
    authorizedOnly: false,
    newTab: false,
    icon: <IconHome size={64} />,
    link: '/chemporium',
    description: 'coming soon',
  },
];
