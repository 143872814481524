import React from 'react';
import styled from 'styled-components/macro';
import ErrorMessage from '../ErrorMessage';
import { getPasswordStrength } from '../../utils';

interface propsT {
  password: string;
}

const PasswordReason = (props: propsT) => {
  const { password } = props;
  const { strength, reason } = getPasswordStrength(password);

  return (
    <PasswordReasonContainer>
      {strength === 5 ? null : <ErrorMessage message={reason} />}
    </PasswordReasonContainer>
  );
};

export default PasswordReason;

const PasswordReasonContainer = styled.div`
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 100px);
`;
