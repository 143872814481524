import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {TileCardT} from "@components/layout/TileCard.type";

interface propsT {
  isAuthorized: boolean;
  card: TileCardT;
}

const debug = false;

const TileCard = (props: propsT) => {
  const { isAuthorized, card } = props;
  debug && console.log("TileCard", props);

  const getCardContent = (active, card) => {
    return(
      <Content active={active}>
        <Header>{card.title}</Header>
        <Icon>{card.icon}</Icon>
        <Description>{card.description}</Description>
        { card.newTab ? <Warning>External Link</Warning> : null }
      </Content>
    )
  }

  const active = !card.authorizedOnly || isAuthorized;
  debug && console.log("card.authorizedOnly", card.authorizedOnly,
      " active:", active);
  // return an inactive button
  if (!active) {
    return (
        <StyledCard active={active}>
          <Link to={card.link}>
            { getCardContent(active, card) }
          </Link>
        </StyledCard>
    )
  }
  // handle whether internal link or external
  if (card.newTab) {
    return (
      <StyledCard active={active}>
        <a href={card.link} target = "_blank" rel = "noopener noreferrer">
          { getCardContent(active, card) }
        </a>
      </StyledCard>
    );
  } else {
    return(
      <StyledCard active={active}>
        <Link to={card.link}>
          { getCardContent(active, card) }
        </Link>
      </StyledCard>
    );
  }
}

export default TileCard;

const StyledCard = styled.div<{active: boolean}>`
  display: flex;
  height: 190px;
  width: 220px;
  margin: 8px;
  padding: 5px;
  border-radius: 20px;
  opacity: ${(p) => p.active ? 1.0: 0.5};
  background-color: ${(p)=> p.active ? 
          p.theme.palette.backgroundTertiary : p.theme.palette.backgroundQuaternary };
  :hover {
    background-color: ${(p)=> p.theme.palette.backgroundQuaternary };
  }
`;

const Content = styled.div<{active: boolean}>`
  display: flex;
  height: 170px;
  width: 200px;
  padding: 10px;
  align-content: center;
  flex-direction: column;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary };
  border-radius: 15px;
  :hover {
    border: 1px solid ${(p)=> p.active ?
            p.theme.palette.accentPrimary : p.theme.palette.accentSecondary };;
  }
`;

const Header = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small };
  min-height: 36px;
  justify-content: center;
  text-align: center;
`;

const Icon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  padding-top: 5px;
  text-align: center;
  font-size: ${(p) => p.theme.sizes.xsmall };
  line-height: ${(p) => p.theme.sizes.small };
  color: ${(p) => p.theme.palette.textSecondary };
`;

const Warning = styled.div`
  padding-top: 10px;
  text-align: center;
  font-style: italic;
  font-size: ${(p) => p.theme.sizes.xsmall };
  color: ${(p) => p.theme.palette.accentSecondary };
`;