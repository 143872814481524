import React from 'react';

// in case need console log messages
const debug = false;

// utility function to take username and convert to initials
export function stringAvatar(userInfo) {
  debug && console.log('stringAvatar | userInfo.regInfo.name', userInfo.regInfo?.name);
  let initials = '?';
  if (userInfo.regInfo?.name) {
    initials = userInfo.regInfo.name?.first[0] + userInfo.regInfo.name?.last[0];
  }
  return <>{initials}</>;
}

// utility function to check if user is signed in (userInfo available)
export function isSignedIn(userInfo) {
  debug && console.log("isSignedIn | userInfo: ", userInfo);
  if (typeof userInfo === 'undefined') {
    return false;
  }
  return userInfo.hasOwnProperty('email');
}