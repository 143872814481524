import { useContext } from "react";
import {UserContext, UserEmpty} from "@contexts/UserContext";
import * as AWSCognitoIdentity from 'amazon-cognito-identity-js';
import {CognitoUserSession, ISignUpResult} from "amazon-cognito-identity-js";
import { jwtDecode } from 'jwt-decode';

export interface ResponseT {
  errors: Array<string>;
  data: Array<any>;
}

const config = require('@app_config/cognito.json');

const useCognito = () => {
  const { user, setUser } = useContext(UserContext);

  // return the access token
  const getToken = () => {
    return user.authSession.getAccessToken().getJwtToken();
  };

  // return the time (seconds) since authentication
  const getAuthRoles = (authSession): Array<string> => {
    try {
      const token = jwtDecode(authSession.getAccessToken().getJwtToken());
      // console.log('token', token);
      if (Object.hasOwn(token, 'cognito:groups')) {
        console.log('roles:', token['cognito:groups']);
        return token['cognito:groups'];
      }
    }
    catch(err) {
      console.error('Error (getAnyRole)', err);
    }
    return [];
  }

  // return the time (seconds) since authentication
  const isTokenExpired = (authSession) => {
    try {
      const token = jwtDecode(authSession.getAccessToken().getJwtToken());
      // console.log('isTokenExpired:', token);
      const timestamp = new Date().getTime();
      return (Math.floor(timestamp/1000) > token['exp']);
    }
    catch(err) {
      console.error('Error', err);
    }
    return true;
  }

  // authenticate the user in cognito user pool
  const login = async(email, password):Promise<CognitoUserSession> => {

    const poolData = { UserPoolId : config.userPoolId, ClientId : config.clientId };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);

    const authenticationDetails = new AWSCognitoIdentity.AuthenticationDetails({
      Username: email, Password: password});

    // console.log("--------Authenticate --- " + username);

    const cognitoUser = new AWSCognitoIdentity.CognitoUser({Username: email, Pool: userPool});

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function(result) {
          resolve(result);
        },
        onFailure: function(error) {
          console.error('ERROR', error.message || error.stringify(error));
          reject(error.message);
        }
      });
    });
  }

  // method to create the user account in cognito with email/password
  const createAccount = async (email, password):Promise<ISignUpResult> => {

    const poolData = { UserPoolId : config.userPoolId, ClientId : config.clientId };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);

    const attributeList = [];

    const dataEmail = { Name : 'email', Value : email };

    const attributeEmail = new AWSCognitoIdentity.CognitoUserAttribute(dataEmail);

    attributeList.push(attributeEmail);

    return new Promise((resolve, reject) => {
      userPool.signUp(email, password, attributeList, null,
          function (error,result) {
            if (error) {
              reject(error.toString());
            } else {
              resolve(result);
            }
          }
      );
    });
  }

  // send the user a code to allow a password reset
  const resetPassword = async(email) => {
    const poolData = { UserPoolId : config.userPoolId, ClientId : config.clientId };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = new AWSCognitoIdentity.CognitoUser({Username: email, Pool: userPool});

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  // method to set new password using emailed verification code
  const confirmPassword = (email, verificationCode, newPassword) => {
    const poolData = { UserPoolId : config.userPoolId, ClientId : config.clientId };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = new AWSCognitoIdentity.CognitoUser({Username: email, Pool: userPool});

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve('success');
        },
      });
    });
  }

  const logout = (email) => {
    const poolData = { UserPoolId : config.userPoolId, ClientId : config.clientId };
    const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = new AWSCognitoIdentity.CognitoUser({Username: email, Pool: userPool});
    cognitoUser.signOut();
    setUser(UserEmpty);
  };

  return {
    getToken,
    getAuthRoles,
    isTokenExpired,
    createAccount,
    login,
    logout,
    resetPassword,
    confirmPassword
  };
};
export default useCognito;
