import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const BackArrowIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.name === 'dark' ? 'white' : 'black';

  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <path
            stroke={color}
            strokeMiterlimit={'10'}
            d='M20.2,9.2h-8.4V6.8c0-1-1.1-1.5-1.9-0.9L3,11.1c-0.6,0.5-0.6,1.4,0,1.9l6.9,5.2c0.8,0.6,1.9,0,1.9-0.9v-2h8.4
	c0.8,0,1.4-0.6,1.4-1.4v-3.1C21.6,9.9,21,9.2,20.2,9.2z'
          />
        </g>
      </svg>
    </>
  );
};

export default BackArrowIcon;
