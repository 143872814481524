import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';
import PagePanel from '@components/pages/PagePanel';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgChemporium = () => {
  const { pageNumber } = useParams();

  return (
    <PageContainer title='Chemporium'>
      <PagePanel title='Chemporium' width={'98%'}>
        <Container></Container>
      </PagePanel>
    </PageContainer>
  );
};

export default PgChemporium;

const Container = styled.div`
  font-size: 123px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-content: center;
  justify-content: center;
`;
