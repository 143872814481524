import styled from 'styled-components/macro';

export const TextTitle = styled.div<{color?: string}>`
  color: ${(p) => p.color === 'accent' ? p.theme.palette.accentPrimary
          : p.theme.palette.titlePrimary};
  font-size: ${(p) => p.theme.sizes.large};
  font-weight: ${(p) => p.theme.weights.heavy};
`;

export const TextRegular = styled.div<{color?: string}>`
  color: ${(p) =>
          p.color === 'accent' ? p.theme.palette.accentPrimary :
          p.color === 'accentSecondary' ? p.theme.palette.accentSecondary :
          p.color === 'primary' ? p.theme.palette.textPrimary :
          p.color && p.color.includes('#') ? p.color :
          p.theme.palette.textSecondary};
  font-size: ${(p) => p.theme.sizes.default};
  font-weight: ${(p) => p.theme.weights.light};
`;

export const TextRegularBold = styled(TextRegular)`
  font-weight: ${(p) => p.theme.weights.heavy};
`;

export const TextSmall = styled(TextRegular)`
  font-size: ${(p) => p.theme.sizes.small};
`;

export const TextSmallBold = styled(TextSmall)`
  font-weight: ${(p) => p.theme.weights.heavy};
`;

export const TextSmallItalics = styled(TextSmall)`
  font-style: italic;
`;

export const TextData = styled(TextRegular)`
  font-size: 11px;
`;

export const TextDataBold = styled(TextData)`
  font-weight: ${(p) => p.theme.weights.heavy};
`;

export const TextDataItalics = styled(TextData)`
  font-style: italic;
`;

export const TextSpace = styled.div<{width?: number}>`
  color: ${(p) => p.theme.palette.textSecondary};
  font-size: ${(p) => p.theme.sizes.small};
  font-weight: ${(p) => p.theme.weights.light};
  width: ${(p) => p.width ? p.width : 5}px;
`;