import React from 'react';
import styled from 'styled-components/macro';
import { ButtonBase } from '@mui/material';

interface propsT {
  label: string;
  onClick: any;
  icon?: JSX.Element | null;
  width?: number;
  outlined?: boolean;
  className?: string;
  style?: any;
}

const ButtonText = (props: propsT) => {
  const {
    label,
    onClick,
    icon = null,
    width = 200,
    outlined,
    className,
    style,
  } = props;
  return (
    <TextButton
      className={className}
      outlined={outlined}
      width={width}
      onClick={onClick}
      style={style}
    >
      {icon} {label}
    </TextButton>
  );
};
export default React.memo(ButtonText);

const TextButton = styled(ButtonBase)<{
  width: number;
  outlined: boolean;
  style: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: ${(p) => p.width}px;
  height: 32px;

  &.MuiButtonBase-root {
    color: ${(p) => p.theme.palette.textPrimary};
    background-color: ${(p) =>
      p.outlined
        ? p.theme.palette.backgroundPrimary
        : p.theme.palette.accentPrimary};
    border-radius: 10px;
    border: 1px solid ${(p) => p.theme.palette.textSecondary};

    :hover {
      background-color: ${(p) => p.theme.palette.accentSecondary};
    }
  }
`;
