import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';
import { getGbgData } from '../data/dataGBG';
import GBGTable from '@components/elements/tables/GBGTable';
import RowPreview from '@components/elements/tables/RowPreview';

const defaultColumns = [
  'Row',
  'Title',
  'Agency',
  'Opportunity #',
  'Category',
  'Funding Level',
];

const previewRowColumns = [
  'Title',
  'Agency',
  'Opportunity #',
  'Category',
  'Description',
  'Funding Level',
  'Max Total Award',
  'Deadline',
];

const debug = false;

const PgGBG = () => {
  const { uuid } = useParams();

  debug && console.log('PgGBG | uuid', uuid);

  // example data for GBG Consulting Mini-App.
  const data = getGbgData();
  debug && console.log('data: ', data);

  // it's easier to have the filter here, without making another call with the same data that we already have here
  const selectedRowData = useMemo(() => {
    return data.rows.find((row) => row.uuid === uuid);
  }, [uuid]);
  debug && console.log('PgBGB | uuid:', uuid, 'selectedRowData:', selectedRowData);

  return (
    <PageContainer title={'GBG Consulting'} >
      {uuid ? (
        <RowPreview
          data={selectedRowData}
          uuid={uuid}
          columns={previewRowColumns}
        />
      ) : (
        <GBGTable defaultColumns={defaultColumns} data={data} />
      )}
    </PageContainer>
  );
};

export default PgGBG;
