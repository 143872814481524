import _ from 'lodash';
import styled from 'styled-components/macro';
import BasicTable from '@as_core/tables/BasicTable';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';

const userFields = [
  {
    label: 'Developer',
    value: 'authEmail',
    type: 'string',
    width: 200,
  },
  {
    label: 'Date',
    value: 'comment_date',
    type: 'date',
    width: 150,
  },
  {
    label: 'Comment',
    value: 'comment',
    type: 'comment',
    width: 200
  }
];

function getValue(report: any, item) {
  if (item?.type === 'boolean') {
    return _.get(report, item.value, '').toString();
  } else if (item?.type === 'date') {
    const value = _.get(report, item.value, '');
    if (value === '') return '';
    const utc = new Date(value);
    return utc.toLocaleDateString() + ' ' + utc.toLocaleTimeString()
  } else if (item?.type === 'comment') {
    const value = _.get(report, item.value, '');
    return <HoverInfoText text={value}/>
  }
  return _.get(report, item.value, '');
}

function getTableRows(comments) {
  const rows = [];
  comments.forEach((c) => {
    const row = {}
    userFields.forEach((f) => {
      row[f.value] = getValue(c, f);
    })
    rows.push(row);
  });
  return rows;
}

const debug = false;
const BugReportComments = (props) => {
  let { comments } = props;
  debug && console.log('BugReportComments | comments:', comments);

  return (
    <CommentsContainer>
      <Comments>Bug Report Comments:</Comments>
      <BasicTable
        fields={userFields}
        rows={getTableRows(comments)}
      />
    </CommentsContainer>
  );
};

export default BugReportComments;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-top: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const Comments = styled.div`
  display: flex;
  width: 100%;
  padding-left: 10px;
  font-size: 12px;
`;