import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from "@contexts/UserContext";

import {userFormFields} from "./forms/config";
import {Row} from "@as_core/account/forms/FormStyles";
import {renderAccountField} from "./utils";
import MenuButton from '@components/controls/MenuButton';
import EditIcon from '@components/icons/edit.icon';
import UploadIcon from '@components/icons/upload.icon';
import CancelIcon from '@components/icons/cancel.icon';

const AccountDetails = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const handleSave = () => {
    console.log('Account Details | handleSave');
  }

  const getEditButtons = () => {
    if (isEditing) {
      return(
        <>
          <MenuButton
            text={'Save Changes'}
            icon={<UploadIcon />}
            margin={'1px'}
            tooltipPlacement='bottom-end'
            onClick={handleSave}
          />
          <MenuButton
            text={'Cancel Changes'}
            icon={<CancelIcon />}
            margin={'1px'}
            tooltipPlacement='bottom-end'
            onClick={()=>setIsEditing(false)}
          />
        </>
      )
    } else {
      return(
        <MenuButton
          text={'Edit My Information'}
          icon={<EditIcon />}
          margin={'1px'}
          tooltipPlacement='bottom-end'
          onClick={()=>setIsEditing(true)}
        />
      )
    }
  }

  return(
    <UserEditContainer>
      <UserAccountInformation>
        <HeaderRow><Title>User Account Details:</Title><EditButtons>{getEditButtons()}</EditButtons></HeaderRow>
        { userFormFields.map((row, index) => (
          <Row width={'100%'} key={'row_' + index}>
            { row.map((f) => renderAccountField(f, index,user.regInfo)) }
          </Row>
        ))}
      </UserAccountInformation>
    </UserEditContainer>
  )
}
export default AccountDetails;

const Title = styled.div`
  display: flex;
  font-size: 18px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const UserAccountInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  min-height: 300px;
  height: max-content;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const EditButtons = styled.div`
  display: flex;
  width: 100px;
  margin-left: 10px;
`;