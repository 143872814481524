import { authCoreClient, authAppClient } from "./base";

export const ApiInfo = {
  get: (token) => authAppClient(token).get('/info/'),
};

export const User = {
  get: (token, config) => authAppClient(token).get('/user', config),
  create: (token, data) => authAppClient(token).post('/user/create', data),
  update: (token, data) => authAppClient(token).post('/user/update', data),
  delete: (token, data) => authAppClient(token).post('/user/delete', data),
};


// AsedaSciences Core Services
export const ApiCoreInfo = {
  get: (token) => authCoreClient(token).get("/info/"),
};

// AsedaSciences Core Services
export const UserRegistration = {
  get: (token, config) => authCoreClient(token).get('/user/', config),
  create: (token, data) => authCoreClient(token).post('/user/create', data),
  update: (token, data) => authCoreClient(token).post('/user/update', data),
  delete: (token, data) => authCoreClient(token).post('/user/delete', data)
};

// AsedaSciences Core Services
export const BugReports = {
  get: (token, config) => authCoreClient(token).get("/bugs", config),
  getCounts: (token, config) => authCoreClient(token).get("/bugs/count", config),
  submit: (token, data) => authCoreClient(token).post("/bugs/submit", data),
  update: (token, data) => authCoreClient(token).put("/bugs/update", data),
  addComment: (token, data) => authCoreClient(token).post("/bugs/add/comment", data),
  delete: (token, id) => authCoreClient(token).post('/bugs/delete', id)
};

// AsedaSciences Core Services
export const Coupons = {
  get: (token, config) => authCoreClient(token).get("/coupons", config),
  getAll: (token) => authCoreClient(token).get("/coupons/list", null),
  create: (token, data) => authCoreClient(token).post("/coupons/create", data),
  update: (token, data) => authCoreClient(token).put("/coupons/update", data),
  delete: (token, data) => authCoreClient(token).delete('/coupons/delete', data)
};
