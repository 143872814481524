import styled from 'styled-components/macro';
import { BugReports } from '@utils/api';
import useCognito from '@as_core/account/useCognito';
import { BugsReportT } from '@as_core/bugsReporting/bugs.types';
import { updateByDot } from '@subApps/bugs/utils';
import ReportInfoHeader from './ReportInfoHeader';
import Acknowledge from '@subApps/bugs/forms/Acknowledge';
import AssignDeveloper from '@subApps/bugs/forms/AssignDeveloper';
import CloseReport from '@subApps/bugs/forms/CloseReport';

function getForm(action, currentReport, handleUpdate, handleCancel) {
  switch(action) {
    case 'new':
      return (
        <Acknowledge
          report={currentReport}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )
    case 'unassigned':
      return(
        <AssignDeveloper
          report={currentReport}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )
    case 'active':
      return(
        <CloseReport
          report={currentReport}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )
    default:
      console.error('UpdateBugReport | unknown form for action', action);
  }
}


type PropsT = {
  action: string;
  report: BugsReportT;
  setUpdated?: () => void;
  closeDialog?: () => void;
}

const debug = false;
const UpdateBugReport = (props:PropsT) => {
  let { action, report } = props;
  debug && console.log('UpdateBugReport | action, report:', action, report);
  const { getToken } = useCognito();

  const _handleUpdate = async (uuid:string, updates: any) => {
    // console.log('UpdateBugReport | _handleUpdate', uuid, updates);
    const payload = { uuid: uuid };
    Object.keys(updates).forEach((k) => {
        updateByDot(payload, k, updates[k]);
      }
    )
    await BugReports.update(getToken(), payload);
    props?.closeDialog && props.closeDialog();
    props?.setUpdated && props.setUpdated();
  }

  return (
    <UserEditContainer>
      <ReportInfoHeader report={report} />
      { getForm(action, report, _handleUpdate, props?.closeDialog) }
    </UserEditContainer>
  );
};

export default UpdateBugReport;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
