import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const AddCommentIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path fill={color} d="M2.3,21.8c-0.4-0.3-0.5-0.9-0.3-1.4l1.7-4.2c-1.4-0.5-2.4-1.9-2.4-3.5V5.6c0-2,1.5-3.7,3.4-3.7h14.6c1.9,0,3.4,1.6,3.4,3.7
	v7.2c0,2-1.5,3.7-3.4,3.7l-7.5,0l-8.2,5.4C3.4,22,3.2,22.1,3,22.1C2.8,22.1,2.5,22,2.3,21.8z M4.7,3.4c-1.1,0-1.9,1-1.9,2.2v7.2
	c0,1.1,0.7,2,1.6,2.1C4.7,15,5,15.2,5.1,15.5c0.2,0.3,0.2,0.7,0.1,1L3.8,20l7.2-4.7c0.3-0.2,0.6-0.3,0.9-0.3h7.4
	c1.1,0,1.9-1,1.9-2.2V5.6c0-1.2-0.9-2.2-1.9-2.2H4.7z M3.8,15.9L3.8,15.9L3.8,15.9z"/>
        <path fill={colorHighlight} d="M15.2,7.5h-1.7V5.8c0-0.8-0.7-1.5-1.5-1.5S10.5,5,10.5,5.8v1.7H8.8C8,7.5,7.3,8.2,7.3,9s0.7,1.5,1.5,1.5h1.7v1.7
	c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-1.7h1.7c0.8,0,1.5-0.7,1.5-1.5S16,7.5,15.2,7.5z"/>
      </svg>
  );
};

export default AddCommentIcon;
