import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  styled as muiStyled,
} from '@mui/material';
import { IRowPreview } from './types';
import BackArrowIcon from '@components/elements/icons/BackArrow';
import MenuButton from '@components/elements/MenuButton';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { FlexBanner, FlexBannerGroup } from '@components/layout/FlexStyles';
import { TextSmallBold } from '@components/elements/TextStyles';
import LinkOutIcon from '@components/elements/icons/LinkOutIcon';
import { Link } from 'react-router-dom';

const debug = false;
const RowPreview = (props: IRowPreview) => {
  const { columns, data } = props;
  const navigate = useNavigate();

  const tableData = columns.map((column) => {
    if (column.toLowerCase() === 'row') return;
    if (column === 'Max Total Award') {
      return {
        column1: column,
        column2: data[column]
          ? data[column].toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          : '',
      };
    } else {
      return {
        column1: column,
        column2: data[column],
      };
    }
  });
  debug && console.log('RowPreview | data:', data);
  const opportunityURL = data?.['link'] ? data['link'] : '';
  debug && console.log('RowPreview | opportunityURL: ', opportunityURL);

  return (
    <Container>
      <FlexBanner>
        <FlexBannerGroup>
          <MenuButton
            onClick={() => navigate('/gbg')}
            icon={<BackArrowIcon />}
          />
        </FlexBannerGroup>
        <FlexBannerGroup>
          <TextSmallBold color={'accent'}>{data?.['Title']}</TextSmallBold>
        </FlexBannerGroup>
        <FlexBannerGroup>
          <Link to={opportunityURL} target='_blank'>
            <MenuButton icon={<LinkOutIcon />} text={'Link to Posting'} />
          </Link>
        </FlexBannerGroup>
      </FlexBanner>
      <Table>
        <ScrollBox>
          <TableBody>
            {tableData.map((item, index) => (
                <TableRow key={index}>
                  <StyledLabelCell>{item?.column1}</StyledLabelCell>
                  <StyledTableCell>{item?.column2}</StyledTableCell>
                </TableRow>
            ))}
          </TableBody>
        </ScrollBox>
      </Table>
    </Container>
  );
};

const StyledLabelCell = muiStyled(TableCell)`
  min-width: 150px;
  border: 0.5px solid white;
`;

const StyledTableCell = muiStyled(TableCell)`
  border: 0.5px solid white;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  width: calc(100% - 20px);
`;

const ScrollBox = styled.div`
  height: calc(100vh - 125px);
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
`;

export default RowPreview;
