// styles.js (or styles.ts)
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';



export const StyledDiv = styled.div`
  width: 65%;
  margin: auto;
  border: 1px solid white;
  border-radius: 30px;
  padding: 2rem;
  background: ${(props) => props.theme.palette.backgroundSecondary};
`;

export const StyledTextField = styled(TextField)`
  height: 50px;
`;

export const StyledButton = styled(Button)`
  border-radius: 30px !important;
  height: 45px;
  width: 150px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    width: 98.5%;
    margin-left: 5px;
`;