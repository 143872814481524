import { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { TextSmall, TextSpace } from '@components/elements/TextStyles';
import {
  Row,
  StyledForm,
  StyledA,
  LoginButton,
  FormLabel,
  ForgotPasswordA,
  BasicTextInput,
} from './FormStyles';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import useCognito from '@as_core/account/useCognito';
import Button from '../fields/Button';
import HoverInfoText from '../fields/HoverInfoText';
import { isValidEmail } from '../utils';
import { UserContext } from '@contexts/UserContext';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { errorMessages } from './config';
import AppDialogHeader from '@as_core/account/components/AppDialogHeader';
import { APP_CONFIG } from '@app_config/app';

/**
 * Component to handle the login functionality for AsedaSciences Apps through
 * AWS Cognito -- sets user context upon success and/or other states
 * @constructor
 */
const debug: boolean = false;
const SignIn = ({ setMessage }) => {
  const { user, setUser } = useContext(UserContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { login, getAuthRoles } = useCognito();

  debug && console.log('SignIn | user:', user, 'errorMessage:', errorMessage);

  // function to handle the submit action if valid form and not in loggingIn state
  const handleLogin = async () => {
    debug && console.log('handleLogin', email, password);
    setErrorMessage('');
    if (email && !isValidEmail(email)) {
      setErrorMessage(errorMessages.invalidEmailFormat);
    } else if (password?.length < 8) {
      setErrorMessage(errorMessages.invalidPasswordLength);
    } else {
      // setMessage('Authenticating');
      setIsAuthenticating(true);
      login(email, password)
        .then((result: CognitoUserSession) => {
          const userId = result.getIdToken().payload;
          setUser((prev) => ({
            ...prev,
            isAuthenticated: true,
            authId: userId['cognito:username'],
            authEmail: userId.email,
            authRoles: getAuthRoles(result),
            authSession: result,
          }));
          setIsAuthenticating(false);
        })
        .catch((error) => {
          setMessage('');
          console.error('Error authenticating: ', error);
          if (error === 'Incorrect username or password.') {
            setErrorMessage(
              'Incorrect email/password or user needs to create an account'
            );
          } else {
            setErrorMessage(error);
          }
          setIsAuthenticating(false);
        });
    }
  };

  const handleKeyDown = (e) => {
    // remove error message if there is any
    if (errorMessage) {
      setErrorMessage('');
    }
    // Check if the pressed key was "Enter"
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Container key={'sign-in-form'}>
      <AppDialogHeader message={'Log in to your Account'} />
      <Row>
        <TextSmall>
          Or <StyledA href={'/user/new'}>create an account</StyledA> if you are
          a new user
        </TextSmall>
        <TextSpace />
        <HoverInfoText text={APP_CONFIG.loginInfo} />
      </Row>
      <StyledForm onSubmit={handleLogin}>
        <Row width='400px' align={'flex-start'}>
          <FormLabel>Email Address</FormLabel>
        </Row>
        <Row width={'400px'} height={'36px'}>
          <BasicTextInput
            id='signin_username'
            type='text'
            name='username'
            placeholder='Enter email as username ...'
            autoComplete='username'
            error={errorMessage !== ''}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Row>
        <Row width={'400px'} align={'space-between'}>
          <FormLabel>Password</FormLabel>
          <FormLabel>
            <ForgotPasswordA href={'/user/forgot'} tabIndex={-1}>
              Forgot Password
            </ForgotPasswordA>
          </FormLabel>
        </Row>
        <Row width={'400px'} height={'36px'}>
          <BasicTextInput
            id='signin_password'
            type='password'
            name='password'
            placeholder='Enter password ...'
            autoComplete='password'
            error={errorMessage !== ''}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Row>
        <Row width='100%' align='flex-start' height='16px'>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        </Row>
        <LoginButton>
          <Button
            type='submit'
            label={'Login'}
            onClick={handleLogin}
            inProcess={isAuthenticating}
          />
        </LoginButton>
      </StyledForm>
    </Container>
  );
};

export default SignIn;

const Container = styled.div`
  width: 450px;
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;
