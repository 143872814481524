import AddComment from '@subApps/bugs/forms/AddComment';
import { BugReports } from '@utils/api';
import { useContext } from 'react';
import { UserContext } from '@contexts/UserContext';
import useCognito from '@as_core/account/useCognito';

const debug = false;
const BugReportAddComment = (props) => {
  let { uuid } = props;
  const {user} = useContext(UserContext);
  const { getToken } = useCognito();
  debug && console.log('BugReportAddComment | uuid:', uuid);

  const _handleUpdate = async (comment:string) => {
    console.log('UpdateBugReport | _handleUpdate', uuid, user.authEmail, comment);
    const payload = {
      uuid: uuid,
      authEmail: user.authEmail,
      comment: comment
    };
    await BugReports.addComment(getToken(), payload);
    props?.closeDialog && props.closeDialog();
  }

  return (
    <AddComment
      handleUpdate={_handleUpdate}
      handleCancel={props.handleCancel}
    />
  );
};

export default BugReportAddComment;