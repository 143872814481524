
export const handleError = (err:any) => {
  if (err?.response?.status === 400) {
    console.log("ERROR", err?.response?.data?.errors);
    err?.response?.data?.errors?.forEach((element:string) => {
      alert(element);
    });
  } else {
    console.log("ERROR CODE", err?.response?.status);
    console.log("ERROR", err?.response?.data);
  }
}

export function truncateString(value: any, maxLength: number) {
  if (value === undefined || !value) return '';
  if (typeof value === 'number') {
    return value.toString();
  }
  if (value.length <= maxLength) {
    return value;
  } else {
    return value.slice(0, maxLength) + '...';
  }
}

