import React, { memo } from 'react';
import { Tooltip } from '@mui/material';

interface TextButtonPropsT {
  tooltip?: string,
  tooltipPlacement?: any,
  text: string,
  icon?: any,
  onClick?: any,
  isActive?: boolean,
  isDisabled?: boolean,
  iconRight?: boolean,
  margin?: string,
  fontSize?: number,
  width?: number,
  height?: number,
}

const TextButton = (props: TextButtonPropsT) => {

  return (
      <Tooltip
        title={props.text}
        placement={props?.tooltipPlacement ? props.tooltipPlacement : 'right'}
        arrow
      >
        <TextButton {...props} />
      </Tooltip>
  );
}
export default memo(TextButton);