import React from 'react';
import styled from 'styled-components/macro';

interface PropsI {
  color: string;
  bars: number;
  level: number;
}

const ProgressBar = (props:PropsI) => {
  const {color, bars, level} = props;

  return (
      <ProgressBarContainer>
        {Array.from({length: bars}, (_, index) => (
            <Bar key={index} color={(index < level) ? color : null } />
        ))}
      </ProgressBarContainer>
  );
};

export default ProgressBar;

const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const Bar = styled.div<{color?: string}>`
  display: flex;
  width: 18%;
  background-color: ${(p) => p.color};
  border: 1px solid ${(p) => p.theme.palette.textSecondary};
  border-radius: 5px;
  height: 8px;
`;
