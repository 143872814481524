import React from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import PagePanel from '@components/pages/PagePanel';
import BugAdministration from '@components/pages/BugAdministration';

const PgBugsAdministration = () => {

  return (
    <PageContainer title={'Bugs administration'} >
      <PagePanel title={'Bugs Administration'} width={'98%'}>
        <Container>
          <BugAdministration />
        </Container>
      </PagePanel>
    </PageContainer>
  );
};

export default PgBugsAdministration;

const Container = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  margin-top: 24px;
`;
