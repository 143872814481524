export const passwordStrengthLabels = {
  0: '',
  1: 'Very Weak',
  2: 'Weak',
  3: 'Moderate',
  4: 'Close',
  5: 'Strong'
}

export const passwordStrengthColors = {
  0: '#999',
  1: '#940',
  2: '#900',
  3: '#990',
  4: '#FF0',
  5: '#0F0'
}