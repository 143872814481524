import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const CustomCurationIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={colorHighlight} d="M264.7,135.2h5.8c2.3,8.9,10.4,15.5,20.1,15.5c11.4,0,20.7-9.3,20.7-20.7s-9.3-20.7-20.7-20.7
	c-9.6,0-17.8,6.6-20.1,15.5h-5.8c-2.3-8.9-10.4-15.5-20.1-15.5c-11.4,0-20.7,9.3-20.7,20.7s9.3,20.7,20.7,20.7
	C254.5,150.8,262.5,144.2,264.7,135.2z M290.7,119.8c5.7,0,10.4,4.7,10.4,10.4c0,5.7-4.7,10.4-10.4,10.4c-5.7,0-10.4-4.7-10.4-10.4
	C280.4,124.4,284.9,119.8,290.7,119.8z M234.4,130.1c0-5.7,4.7-10.4,10.4-10.4c5.7,0,10.4,4.7,10.4,10.4s-4.7,10.4-10.4,10.4
	C239.1,140.5,234.4,135.8,234.4,130.1z"/>
        <path fill={color} d="M215.3,315.6c0.6-2.2-0.7-4.5-2.9-5l-38-10.2c-2.2-0.6-4.5,0.7-5,2.9c-0.6,2.2,0.7,4.5,2.9,5l38,10.2
			C212.5,319.1,214.7,317.8,215.3,315.6z"/>
        <path fill={colorHighlight} d="M139.2,366.3l45.5,12.2c3.4,0.9,6.7-1.7,6.7-5.1v-40.8c0-2.4-1.7-4.5-3.9-5.1L142,315.2
			c-3.4-0.9-6.7,1.7-6.7,5.1v40.8C135.3,363.5,136.8,365.7,139.2,366.3z M143.5,324.3l39.6,10.6v34.6l-39.6-10.6V324.3z"/>
        <path fill={color} d="M240.4,339.5l-33.8-9c-2.2-0.6-4.5,0.7-5,2.9c-0.6,2.2,0.7,4.5,2.9,5l33.8,9c2.2,0.6,4.5-0.7,5-2.9
			C243.9,342.3,242.6,340,240.4,339.5L240.4,339.5z"/>
        <path fill={color} d="M204.4,384.6l33.8,9c2.2,0.6,4.5-0.7,5-2.9s-0.7-4.5-2.9-5l-33.8-9c-2.2-0.6-4.5,0.7-5,2.9
			C200.8,381.7,202.2,384,204.4,384.6z"/>
        <path fill={color} d="M202.2,353.8l47.9,12.8c2.2,0.6,4.5-0.7,5-2.9c0.6-2.2-0.7-4.5-2.9-5l-47.9-12.8c-2.2-0.6-4.5,0.7-5,2.9
			C198.7,351,200,353.2,202.2,353.8z"/>
        <path fill={color} d="M200.6,369l47.9,12.8c2.2,0.6,4.5-0.7,5-2.9c0.6-2.2-0.7-4.5-2.9-5L202.7,361c-2.2-0.6-4.5,0.7-5,2.9
			C197.1,366.2,198.4,368.4,200.6,369z"/>
        <path fill={colorHighlight} d="M249.8,421.3l-45.5-12.2c-3.4-0.9-6.7,1.7-6.7,5.1V455c0,2.4,1.7,4.5,3.9,5.1l45.5,12.2
			c3.4,0.9,6.7-1.7,6.7-5.1v-40.8C253.7,424,252.1,422,249.8,421.3z M245.4,463.3l-39.6-10.6v-34.6l39.6,10.6V463.3z"/>
        <path fill={color} d="M188.4,408.9l-48-12.8c-2.2-0.6-4.5,0.7-5,2.9s0.7,4.5,2.9,5l47.9,12.8c2.2,0.6,4.5-0.7,5-2.9
			C191.9,411.7,190.6,409.5,188.4,408.9z"/>
        <path fill={color} d="M171.5,445.9l-31-8.4c-2.2-0.6-4.5,0.7-5,2.9s0.7,4.5,2.9,5l31,8.4c2.2,0.6,4.5-0.7,5-2.9
			C175,448.8,173.7,446.6,171.5,445.9z"/>
        <path fill={color} d="M188.4,423.3l-48-12.8c-2.2-0.6-4.5,0.7-5,2.9c-0.6,2.2,0.7,4.5,2.9,5l47.9,12.8c2.2,0.6,4.5-0.7,5-2.9
			C191.9,426.1,190.6,423.9,188.4,423.3z"/>
        <path fill={color} d="M188.4,437.6l-48-12.8c-2.2-0.6-4.5,0.7-5,2.9c-0.6,2.2,0.7,4.5,2.9,5l47.9,12.8c2.2,0.6,4.5-0.7,5-2.9
			C191.9,440.6,190.6,438.2,188.4,437.6z"/>
        <path fill={colorHighlight} d="M340.8,347.9l45.5-12.2c2.3-0.6,3.9-2.7,3.9-5.1v-40.8c0-3.5-3.3-6-6.7-5.1L338,296.8
			c-2.3,0.6-3.9,2.7-3.9,5.1v40.8C334.2,346.3,337.5,348.8,340.8,347.9L340.8,347.9z M342.4,304.3l39.6-10.6v34.6l-39.6,10.6V304.3z
			"/>
        <path fill={color} d="M291.5,318l34.1-9.1c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-34.1,9.1c-2.2,0.6-3.5,2.8-2.9,5
			C287,317.3,289.3,318.6,291.5,318L291.5,318z"/>
        <path fill={color} d="M277.8,364.8l33.8-9c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-33.8,9c-2.2,0.6-3.5,2.8-2.9,5
			C273.3,364.1,275.6,365.4,277.8,364.8L277.8,364.8z"/>
        <path fill={color} d="M277.7,336.1l47.9-12.8c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-47.9,12.8c-2.2,0.6-3.5,2.8-2.9,5
			C273.2,335.4,275.4,336.6,277.7,336.1z"/>
        <path fill={color} d="M277.7,350.4l47.9-12.8c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-47.9,12.8c-2.2,0.6-3.5,2.8-2.9,5
			C273.2,349.8,275.5,351,277.7,350.4z"/>
        <path fill={colorHighlight} d="M383.7,403.6l-107.2,28.6c-2.3,0.6-3.9,2.7-3.9,5.1v42.9c0,3.5,3.3,6,6.7,5.1l107.1-28.7
			c2.3-0.6,3.9-2.7,3.9-5.1v-42.9C390.3,405.2,387,402.6,383.7,403.6z M382.1,449.3l-101.2,27.1v-36.8l101.2-27.1V449.3z"/>
        <path fill={color} d="M291.5,377.7l95.8-25.6c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-95.8,25.6c-2.2,0.6-3.5,2.8-2.9,5
			C287,376.9,289.3,378.3,291.5,377.7L291.5,377.7z"/>
        <path fill={color} d="M277.7,424.5l61.7-16.5c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-61.7,16.5c-2.2,0.6-3.5,2.8-2.9,5
			C273.2,423.9,275.5,425.1,277.7,424.5z"/>
        <path fill={color} d="M277.7,395.8l109.6-29.3c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-109.6,29.3c-2.2,0.6-3.5,2.8-2.9,5
			C273.2,395.1,275.4,396.4,277.7,395.8z"/>
        <path fill={color} d="M277.7,410.2l109.6-29.3c2.2-0.6,3.5-2.8,2.9-5c-0.6-2.2-2.8-3.5-5-2.9l-109.6,29.3c-2.2,0.6-3.5,2.8-2.9,5
			C273.2,409.5,275.4,410.7,277.7,410.2z"/>
        <path fill={color} d="M397.5,267.2l-24.3,6.5v-25.8c0-2.1-1.2-4.1-2.9-5c-1.8-1.1-4.1-1.1-5.9,0l-52.6,31.1
			c-1.9,1.2-2.6,3.7-1.5,5.6c1.2,1.9,3.7,2.6,5.6,1.5l49-28.9v23.8l-73.8,19.8l7.6-4.5c1.9-1.2,2.6-3.7,1.5-5.6
			c-1.2-1.9-3.7-2.6-5.6-1.5l-31.9,18.7l-103.4-52.5c-3.9-1.9-8.5,0.9-8.5,5.2v17.8l-22.6-6c-3.4-0.9-6.7,1.7-6.7,5.1v189.4
			c0,2.4,1.7,4.5,3.9,5.1l133.3,35.7c2.7,0.7,5.5,0.7,8.3,0L400.2,467c2.3-0.6,3.9-2.7,3.9-5.1V272.5
			C404.1,268.9,400.8,266.3,397.5,267.2z M159.1,259.5l66.6,33.8l-66.6-17.9L159.1,259.5L159.1,259.5z M129.8,276.2
			c14.9,4,107.5,28.8,129,34.6v103.4c0,2.3,1.8,4.2,4.2,4.2c2.3,0,4.2-1.8,4.2-4.2V310.8c22.6-6,103.2-27.7,103.2-27.7l25.6-6.9
			v183.2L267,494v-60.4c0-2.3-1.8-4.2-4.2-4.2s-4.2,1.8-4.2,4.2V494l-128.9-34.6V276.2z"/>
        <path fill={color} d="M262.2,263.5c8.6,1,17.2-0.2,25.2-3.3l-17,47.9c-0.2,0.4-0.2,0.9-0.3,1.4l14-9l23.5-66c6,3.5,9.3,3.7,34.9,12.8
	c4.6,1.6,8.7,4.3,12,7.8l8.8-5.7c-4.6-5.4-10.7-9.6-17.4-11.9l-27.9-9.6c-5.4-1.8-10.1-5.2-13.7-9.7c-1.7-2.2-5-2.6-7.3-0.9
	c-2.2,1.7-2.6,5-0.9,7.3c0.9,1.1,1.8,2.1,2.8,3.1l-6.4,18c-8.4,6-18.8,8.8-29.1,7.6c-2.8-0.3-5.4,1.7-5.7,4.6
	C257.4,260.6,259.3,263.2,262.2,263.5z"/>
        <path fill={color} d="M330.1,270l-0.3-8.5c0-3.1-2.4-5.3-5.3-5.1c-2.8,0.1-5,2.5-5,5.3l0.5,14.8L330.1,270z"/>
        <path fill={color} d="M365.6,101.1c-1.4-128.3-193.8-129-195.3,0c-0.1,9,1.1,18,3.3,26.5c3.3,12.1,13.5,21,25.8,22.7
	c1.8,13,7.4,25.2,16,35.3c1.8,2.1,5.1,2.4,7.3,0.6c2.1-1.8,2.4-5.1,0.6-7.3c-9.2-10.7-14.3-24.3-14.3-38.5V98.6
	c0-14.3,11.6-25.9,25.9-25.9H301c14.3,0,25.9,11.6,25.9,25.9v42c0,43-44,70.2-81.7,54.2c-4.2-1.7-8.4,2.3-6.9,6.5
	c-0.6,12-8.5,22.6-20.1,26.6l-27.9,9.6c-10.7,3.7-19.1,11.5-23.6,21.3l9.2,4.6c3.3-7.4,9.5-13.4,17.6-16.1c0,0.1-0.6,0.2,13.1-4.5
	l-1.2,35.5l10.2,5.1l1.4-44.4c4.6-1.6,7.4-2.3,11.3-4.7l24.1,67.6l13.3,6.7c0-0.2-0.1-0.5-0.2-0.7l-28.4-80.3
	c5.5-5.7,9.3-13,10.8-21c43.6,13.2,88.9-19.1,89.2-65.7c0-0.1,0-42.5,0-42.5c0-19.9-16.2-36.2-36.2-36.2H235
	c-19.9,0-36.2,16.2-36.2,36.2v41.2c-7.3-1.8-13.1-7.4-15.1-14.8c-2-7.7-3.1-15.7-3-23.8c1.3-115.3,173.3-114.8,174.6,0
	c0.1,8.1-0.9,16-3,23.8c-0.7,2.8,0.9,5.5,3.6,6.4c2.8,0.7,5.5-0.9,6.4-3.6C364.7,119,365.8,110.1,365.6,101.1z"/>
      </svg>
    </>
  )
}

export default CustomCurationIcon;