import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';
import { BugsReportT } from '@as_core/bugsReporting/bugs.types';
import { getTitleInfo } from '@subApps/bugs/utils';

type PropsT = {
  report: BugsReportT
}

const ReportInfoHeader = (props:PropsT) => {
  return(
    <InfoHeaderContainer>
      <FlexItem>{ getTitleInfo(props.report) }</FlexItem>
    </InfoHeaderContainer>
  )
}
export default ReportInfoHeader;

const InfoHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
`;
