import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const IconHome = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.name === 'dark' ? 'white' : 'black';
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill={color}
          d='M17.3,22H6.7C4.1,22,2,19.9,2,17.3v-6.6C2,9.3,2.7,7.9,3.8,7L9.2,3c1.7-1.3,4-1.3,5.7,0l1.7,1.3v-1
	c0-1,1.5-1,1.6,0v2.6c0,0.3-0.2,0.6-0.4,0.7c-0.3,0.1-0.6,0.1-0.8-0.1l-2.9-2.2c-1.1-0.9-2.7-0.9-3.8,0L4.8,8.3
	C4,8.8,3.6,9.8,3.6,10.7v6.6c0,1.7,1.4,3.1,3.1,3.1h10.6c1.7,0,3.1-1.4,3.1-3.1v-6.6c0-1-0.5-1.9-1.2-2.5c-0.3-0.3-0.4-0.8-0.1-1.1
	c0.3-0.3,0.8-0.4,1.1-0.1c1.1,0.9,1.8,2.3,1.8,3.7v6.6C22,19.9,19.9,22,17.3,22z'
        />
        <path
          fill={colorHighlight}
          d='M10.4,11c-0.5,0-1,0.4-1,1c0.1,1.3,1.9,1.3,2,0C11.4,11.5,11,11,10.4,11z'
        />
        <path
          fill={colorHighlight}
          d='M14.5,12c-0.1,1.3-1.9,1.3-2,0C12.6,10.7,14.5,10.7,14.5,12z'
        />
        <path
          fill={colorHighlight}
          d='M11.4,15.1c-0.1,1.3-1.9,1.3-2,0C9.5,13.8,11.4,13.8,11.4,15.1z'
        />
        <path
          fill={colorHighlight}
          d='M14.5,15.1c-0.1,1.3-1.9,1.3-2,0C12.6,13.8,14.5,13.8,14.5,15.1z'
        />
      </svg>
    </>
  );
};

export default IconHome;
