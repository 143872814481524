import { StyleContext } from '@theme/AppStyles';
import { useContext } from 'react';

const CouponsIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;

  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={size}
      height={size}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g>
        <path fill={colorHighlight} d="M324.3,192.3c-7.9,4.5-10.5,14.7-5.5,23.3c5.2,9,15.4,12.1,23.3,7.6s10.3-15,5.1-24
		C342.3,190.6,332.2,187.7,324.3,192.3z"/>
        <path fill={colorHighlight} d="M273.3,222l-6.6,3.8L275,240l6.3-3.7c4.5-2.6,5-6.9,2.5-11.1S277.5,219.5,273.3,222z"/>
        <path fill={colorHighlight} d="M369.7,74.9l-327.4,189c-17.2,9.9-23.1,31.9-13.2,49.1l64,110.9c9.9,17.2,31.9,23.1,49.1,13.2l327.4-189
		c17.2-9.9,23.1-31.9,13.2-49.1L418.8,88C408.9,70.8,386.9,64.9,369.7,74.9z M134.5,358.7c-16.5,9.5-34.4,4.2-43.4-11.4
		c-8.8-15.3-3.9-33.9,12-43c13-7.5,24-4,24-4l-0.4,13.2c0,0-9-2-16.3,2.2c-10.1,5.8-10.6,16-6.2,23.6c4.5,7.8,14.3,12.9,24,7.2
		c8-4.6,10.2-14.6,10.2-14.6l12.1,5.6C150.6,337.6,148.3,350.8,134.5,358.7z M187.2,328.3c-15.9,9.2-34.5,3.8-43.4-11.6
		c-8.7-15.1-3.9-33.6,12-42.8c15.9-9.2,34.3-4.1,43,11C207.7,300.4,203.1,319.2,187.2,328.3z M255,262.1c6.8,11.9,3,25.3-10.2,32.9
		c-13.4,7.7-26.9,4.4-33.8-7.5l-19.2-33.3l12.9-7.4l19.2,33.3c3.2,5.5,8.5,6.4,14,3.2c5.5-3.2,7.4-8.2,4.2-13.7l-19.2-33.3l12.9-7.4
		L255,262.1z M290.3,245.9l-9,5.2l9.4,16.3l-12.9,7.4l-30.4-52.6l21.9-12.6c10.2-5.9,21.5-2.4,27.6,8.2
		C303,228.3,300.5,240,290.3,245.9z M348.9,234.9c-15.9,9.2-34.5,3.8-43.4-11.6c-8.7-15.1-3.9-33.6,12-42.8s34.3-4.1,43,11
		C369.5,207,364.9,225.7,348.9,234.9z M429.2,187.4l-13,7.5l-28.8-16.1c-4.1-2.3-9.6-6.4-9.6-6.4l-0.1,0.1c0,0,3.9,5.3,5.8,8.6
		l14.2,24.5l-12.9,7.4l-30.4-52.6l13-7.5l28.8,16.2c4.1,2.3,9.6,6.4,9.6,6.4l0.1-0.1c0,0-3.9-5.3-5.8-8.6l-14.2-24.6l12.9-7.4
		L429.2,187.4z"/>
        <path fill={colorHighlight} d="M162.5,285.7c-7.9,4.5-10.5,14.7-5.5,23.3c5.2,9,15.4,12.1,23.3,7.6s10.3-15,5.1-24
		C180.5,284,170.4,281.2,162.5,285.7z"/>
      </g>
      <path fill={color} d="M492.8,257.8L471.7,270l-5-8.7l21.1-12.2L492.8,257.8z M450.7,282.2l-21,12.1l-5-8.7l21-12.1L450.7,282.2z M408.7,306.4
	l-21,12.1l-5-8.7l21-12.1L408.7,306.4z M366.7,330.7l-21,12.1l-5-8.7l21-12.1L366.7,330.7z M324.7,354.9l-21,12.1l-5-8.7l21-12.1
	L324.7,354.9z M282.7,379.2l-21,12.1l-5-8.7l21-12.1L282.7,379.2z M240.7,403.4l-21,12.1l-5-8.7l21-12.1L240.7,403.4z M198.6,427.7
	l-21,12.1l-5-8.7l21-12.1L198.6,427.7z M156.6,451.9l-17.3,10c-1.4,0.8-2.8,1.5-4.3,2.2l-4-9.2c1.1-0.5,2.2-1,3.3-1.6l17.3-10
	L156.6,451.9z M510.4,237.2l-9.3-3.6c2.5-6.6,2.8-13.8,0.7-20.5l9.5-3C514.1,218.9,513.8,228.5,510.4,237.2z M108,466
	c-8.9-2.5-16.9-7.9-22.3-15.4l8.1-5.9c4.1,5.7,10.1,9.8,16.8,11.6L108,466z M499.5,187.7l-8.7,5l-12.1-21l8.7-5L499.5,187.7z
	 M81.8,424.2l-8.7,5l-12.1-21l8.7-5L81.8,424.2z M475.2,145.7l-8.7,5l-12.1-21l8.7-5L475.2,145.7z M57.5,382.2l-8.7,5l-12.1-21
	l8.7-5L57.5,382.2z M451,103.7l-8.7,5l-12.1-21l8.7-5L451,103.7z M33.3,340.2l-8.7,5l-12.1-21l8.7-5L33.3,340.2z M418.2,67.3
	c-4.1-5.6-10.1-9.8-16.9-11.6L404,46c9,2.4,16.9,7.9,22.3,15.4L418.2,67.3z M10.2,298.8l-9.5,3c-2.8-8.8-2.4-18.4,0.9-27.1l9.3,3.6
	C8.4,284.9,8.1,292.2,10.2,298.8z M380.9,57.1c-1.1,0.5-2.2,1-3.2,1.6l-17.4,10.1l-5-8.7l17.4-10.1c1.4-0.8,2.8-1.5,4.2-2.1
	L380.9,57.1z M339.3,80.9l-21,12.1l-5-8.7l21-12.1L339.3,80.9z M297.3,105.1l-21,12.1l-5-8.7l21-12.1L297.3,105.1z M255.3,129.4
	l-21,12.1l-5-8.7l21-12.1L255.3,129.4z M213.3,153.7l-21,12.1l-5-8.7l21-12.1L213.3,153.7z M171.3,177.9l-21,12.1l-5-8.7l21-12.1
	L171.3,177.9z M129.3,202.2l-21,12.1l-5-8.7l21-12.1L129.3,202.2z M87.2,226.4l-21,12.1l-5-8.7l21-12.1L87.2,226.4z M45.2,250.7
	l-20.9,12.1l-5.1-8.6l0.2-0.1l20.9-12L45.2,250.7z"/>
    </svg>
  );
};

export default CouponsIcon;
