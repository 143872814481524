import { ApiInfo } from '@utils/api';
import { handleError } from '@utils/misc';

// ###########################################################################
// TYPES
// ###########################################################################
interface InfoUserT {
  id: string;
  orgId: string;
  user: string;
}

interface InfoApiT {
  build_id: string;
  build_run_type: string;
  build_type: string;
  description: string;
  title: string;
  version: string;
}

interface InfoDBT {
  organization: string;
  owner: string;
  type: string;
  version: string;
}

export interface ApiInfoT {
  user: InfoUserT;
  api: InfoApiT;
  db: InfoDBT;
}

export interface ApiInfoResponseT {
  errors: any[];
  info: ApiInfoT;
}

export const useApiInfo = () => {

  const getApiInfo = async (
    token: string
  ): Promise<ApiInfoResponseT> => {
    let resp;

    try {
      resp = await ApiInfo.get(token);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getApiInfo ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  return {
    getApiInfo
  }
}