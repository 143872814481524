import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import PgHome from '@pages/PgHome';
import PgPage from '@pages/PgPage';
import PgUserAccount from '@pages/PgUserAccount';
import PgUserRegistration from '@pages/PgUserRegistration';
import PgBugsAdministration from '@pages/PgBugsAdministration';
import PgBugsAdmin from '@subApps/bugs/PgBugsAdmin';
import PgCouponsAdmin from '@subApps/coupons/PgCouponsAdmin';
import PgCuration from '@pages/PgCuration';
import PgGBG from '@pages/PgGBG';
import PgChemporium from '@pages/PgChemporium';

const RoutesProtected = () => {
  return (
    <Content>
      <Routes>
        <Route path='/' element={<Navigate replace to='/home' />} />
        <Route path='/home' element={<PgHome />} />
        <Route path='/user-registration' element={<PgUserRegistration />} />
        <Route path='/user' element={<PgUserAccount />} />
        <Route path='/page/:pageNumber' element={<PgPage />} />
        <Route path='/curation' element={<PgCuration />} />
        <Route path='/bug-administration' element={<PgBugsAdministration />} />
        <Route path='/bugs/:action' element={<PgBugsAdmin />} />
        <Route path='/coupons' element={<PgCouponsAdmin />} />
        <Route path='/gbg/:uuid' element={<PgGBG />} />
        <Route path='/gbg' element={<PgGBG />} />
        <Route path='/chemporium' element={<PgChemporium />} />
        <Route path='/*' element={<Navigate replace to='/' />} />
        <Route path='*' element={<Navigate replace to='/' />} />
        {/* handle 404 issue on Amazon S3-Hosted */}
      </Routes>
    </Content>
  );
};

export default RoutesProtected;

const Content = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 50px;
  width: calc(100vw - 50px);
  height: 100vh;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;
