import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const LinkOutIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M18,3.8h-5v1h5c0.2,0,0.3,0,0.5,0.1l-8.5,8.5l0.7,0.7l8.5-8.5c0.1,0.1,0.1,
            0.3,0.1,0.4v5h1V6C20.2,4.8,19.2,3.8,18,3.8z"/>
          <path fill={colorHighlight} d="M19.2,18c0,0.7-0.6,1.2-1.2,1.2H6c-0.7,0-1.2-0.6-1.2-1.2V6c0-0.7,0.6-1.2,
            1.2-1.2h5v-1H6c-1.2,0-2.2,1-2.2,2.2v12
		        c0,1.2,1,2.2,2.2,2.2h12c1.2,0,2.2-1,2.2-2.2v-5h-1V18z"/>
        </g>
      </svg>
    </>
  )
}

export default LinkOutIcon;