import { StyleContext } from '@theme/AppStyles';
import { useContext } from 'react';

const BugsUnassignedIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;

  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={size}
      height={size}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <path fill={color} d="M103.4,321.2c-44.6,7.1-78.8,45.8-78.8,92.4v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7
	c0-30.2,21-55.6,49.2-62.3V321.2"/>
      <path fill={color} d="M103.4,260.9H14.8c-8.2,0-14.8,6.6-14.8,14.8c0,8.2,6.6,14.8,14.8,14.8h88.6V260.9z"/>
      <path fill={color} d="M103.4,200.1c-28.2-6.7-49.2-32.1-49.2-62.3v-19.7c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8v19.7
	c0,46.6,34.2,85.3,78.8,92.4V200.1z"/>
      <path fill={color} d="M408.7,321.2v30.1c28.2,6.7,49.2,32.1,49.2,62.3v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7
	C487.4,367,453.2,328.3,408.7,321.2z"/>
      <ellipse fill={colorHighlight} stroke={color} strokeWidth={15} cx="290.6" cy="93.4" rx="81.4" ry="82.2"/>
      <g>
        <path fill={color} d="M188.7,32.8c5.7-9.5,12.7-18.2,20.7-25.8c-3.8-6.2-11.7-8.8-18.6-5.7c-7.5,3.3-10.8,12-7.5,19.5L188.7,32.8z"/>
        <path fill={color} d="M172.1,93.4c0-15.3,2.9-29.9,8.2-43.3c-22.1,10.1-37.6,32.4-37.6,58.2v54.2h29.6v-54.2c0-1.5,0.1-2.9,0.3-4.4
		C172.3,100.5,172.1,96.9,172.1,93.4z"/>
      </g>
      <path d="M472.6,103.3c-8.2,0-14.8,6.6-14.8,14.8v19.7c0,22.2-11.4,41.9-28.6,53.3c5.5,8.1,10.4,16.7,14.7,25.7
	c26.2-16.6,43.6-45.8,43.6-79v-19.7C487.4,109.9,480.8,103.3,472.6,103.3z"/>
      <path fill={colorHighlight} stroke={color} strokeWidth={15} d="M148,284.2c0-79.2,63.8-143.3,142.6-143.3S433.2,205,433.2,284.2H148z"/>
      <path fill={colorHighlight} d="M379,298.6v60.8c0,67.9-55.2,123.1-123.1,123.1s-123.1-55.2-123.1-123.1v-60.8h-13.5c0-25.3,4.8-49.4,13.5-71.3
	V192h18.6c7.3-10.8,15.6-20.7,24.8-29.5H128c-13.6,0-24.6,11-24.6,24.6v172.3c0,84.2,68.5,152.6,152.6,152.6s152.6-68.5,152.6-152.6
	v-60.8H379z"/>
      <path fill={color} d="M497.1,260.9h-40c2.2,9.6,3.6,19.5,4.3,29.6h35.6c8.2,0,14.8-6.6,14.8-14.8C511.9,267.5,505.3,260.9,497.1,260.9z"/>
    </svg>
  );
};

export default BugsUnassignedIcon;
