import React from 'react';
import SideNav from './SideNav';

import {menuItems} from "@app_config/sideMenu";

const SideMenu = () => {
  return <SideNav menu={menuItems} isAuthorized={true}/>;
};

export default SideMenu;
