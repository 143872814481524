import React from 'react';

import HomeIcon from '@components/icons/IconHome';
import BugsNewIcon from '@as_core/icons/bugsNew.icon';
import BugsUnassignedIcon from '@as_core/icons/bugsUnassigned.icon';
import BugsActiveIcon from '@as_core/icons/bugsActive.icon';
import BugsClosedIcon from '@as_core/icons/bugsClosed.icon';
import BugsDeferredIcon from '@as_core/icons/bugsDeferred.icon';
import CouponsIcon from '@as_core/icons/coupons.icon';

export const menuItems = [
  {
    id: 'home',
    name: 'Home',
    label: 'home-page',
    linkTo: '/home',
    icon: <HomeIcon />,
  },
  {
    id: 'brNew',
    name: 'New - Unacknowledged',
    linkTo: '/bugs/new',
    icon: <BugsNewIcon />,
    subApp: ['bugs']
  },
  {
    id: 'brUnassigned',
    name: 'Unassigned',
    linkTo: '/bugs/unassigned',
    icon: <BugsUnassignedIcon />,
    subApp: ['bugs']
  },
  {
    id: 'brActive',
    name: 'Active',
    linkTo: '/bugs/active',
    icon: <BugsActiveIcon />,
    subApp: ['bugs']
  },
  {
    id: 'brClosed',
    name: 'Closed',
    linkTo: '/bugs/closed',
    icon: <BugsClosedIcon />,
    subApp: ['bugs']
  },
  {
    id: 'brDeferred',
    name: 'Deferred',
    linkTo: '/bugs/deferred',
    icon: <BugsDeferredIcon />,
    subApp: ['bugs']
  },
  {
    id: 'coupons',
    name: 'Coupons',
    linkTo: '/coupons',
    icon: <CouponsIcon />,
    subApp: ['coupons']
  },
];
