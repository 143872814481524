import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const IconGBGLogo = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.name === 'dark' ? 'white' : 'black';
  // const colorGBG = '#031E46';
  return (
    <>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 388.2 452.2'
        width={size}
        height={size}
      >
        <path
          fill={color}
          d='M361.4,304.1c0,1.3-0.1,2.7-0.1,4c0,44.8,0,89.6,0,134.3c0,1.2,0,2.4,0,3.9c-6.8,0-13.3,0.2-19.7-0.1
	c-2-0.1-4-1.6-6-2.5c-7.9-3.6-15.7-7.3-23.6-10.8c-1.3-0.6-3.2-0.6-4.5,0c-20.5,9.6-42.3,14.9-64.7,17.4c-18.6,2-37.2,2.5-55.9,1.3
	c-39.4-2.5-76.2-13.3-109.2-35.1c-38.2-25.3-62.1-60.6-72.6-105.2c-4.1-17.7-5.7-35.7-5-53.8c1.5-37.8,12-72.6,34.9-103.2
	c18.5-24.7,42.4-42.7,70.4-55.3c21.5-9.7,44-15.7,67.3-18.9c3.2-0.4,6.4-0.7,9.9-1.2c0,7.4,0,14.7,0,22.4c2.3-0.5,4.2-0.7,6-1.3
	c0.6-0.2,1.1-1.4,1.2-2.2c0.1-3.1,0.2-6.3,0-9.4c-0.2-5.4,1.5-9.9,6.1-13.1c0.3,0.2,0.7,0.4,1,0.6c0,1.3,0,2.6,0,3.9
	c0,0.2,0,0.4,0,0.6c0,5.3,0.2,10.7,0,16c-0.1,3,1.6,2.8,3.6,2.9c2.2,0.1,3.7-0.1,3.5-3c-0.2-2.8-0.1-5.7,0-8.6
	c0.2-6.9-1.3-13.9,3.2-20.6c-11.5-0.9-22.2-0.1-33.3,2.9c1.4-3.7,2.5-6.9,3.8-10c0.3-0.6,1.2-1.2,2-1.4c4.7-1.2,9.4-2.4,14.1-3.3
	c1.7-0.3,2.6-0.7,2.5-2.6c0-1,0-2.1,0.1-3.1c0.3-2-0.3-3.4-2.3-4.3c-3.5-1.6-4.1-4.6-3.1-8.5c1.3,0,2.7,0,4.1,0
	c3.5,0,5.3-1.3,6.1-4.7c1.2-5.1,0.2-9.7-2.8-14.1c-3.8-5.5-2.3-14.2,3.1-18.3c2.6,1.2,3.7,3.6,4.5,6.1c0.2,0.6,0.3,1.3,0.5,1.9
	c1,5.6,2.5,8.8,5.9,12.5c0.1,0.2,0.2,0.4,0.3,0.6c0,6.8,0.1,13.6,0.1,20.5c-0.1,0.4-0.2,0.8-0.3,1.2c-0.7,0.7-1.3,1.4-2,2
	c-1,0.9-3,1.7-3.1,2.6c-0.1,2.3,0.5,4.7,1.1,7c0.1,0.5,1.3,0.8,2.1,1c4.4,1.1,8.8,2.2,13.2,3.4c0.8,0.2,1.7,1,2,1.7
	c1.3,3.1,2.4,6.2,3.3,9.4c0.2,0.7-0.5,1.9-1.2,2.5c-2.1,2-4.3,3.7-7,6.1c2,0.4,3.4,0.8,4.8,0.8c20.1,0.1,39.7,3.4,59,8.9
	c9.5,2.7,18.7,6.4,28,9.8c1.7,0.6,2.6,0.6,3.9-0.8c6.9-7.5,13.9-14.9,21-22.2c0.6-0.7,1.7-1.2,2.6-1.3c2.7-0.2,5.5-0.1,8.5-0.1
	c0.1,0.9,0.2,1.7,0.3,2.6c0.2,6.4,0.3,12.7,0.6,19.1c0.5,10.7,1.2,21.4,1.7,32.1c0.4,9.4,0.7,18.8,1.1,28.1
	c0.7,15.3,1.5,30.5,2.3,45.8c0.1,2.5-0.6,3.3-3.1,3.3c-4.5,0.1-9.1,0.7-13.6,0.8c-1.4,0-3.1-0.8-4.2-1.7
	c-8.3-6.8-16.6-13.7-24.8-20.6c-0.9-0.7-1.8-1.3-2.8-2c-0.1-0.1-0.3-0.2-0.4-0.4c0,0.2,0,0.5,0,0.7c3.2,8.6,6.4,17.3,9.6,25.9
	c0.4,1,1.2,1.9,2,2.6c3.5,2.9,7.1,5.8,10.8,8.4c1.8,1.3,2.6,2.7,2.4,5c-0.2,2,0,4,0,6.6c-80.4-7.5-160.7-8-241.1-1.3
	c-0.2,2.6-0.5,5.1-0.7,8c1.2,0,2.2,0,3.1,0c12.5-0.4,25.1-0.8,37.6-1.1c12.4-0.4,24.7-0.9,37.1-1.1c20.2-0.3,40.3-0.5,60.5-0.5
	c8.2,0,16.3,0.3,24.5,0.6c14.4,0.5,28.9,1.1,43.3,1.7c11.8,0.5,23.5,1.2,35.7,1.9c0,3.1,0.1,6.1-0.1,9.1c0,0.5-0.9,1.1-1.5,1.5
	c-2.1,1.1-4.3,2-6.4,3c-0.1,0.2-0.2,0.4-0.4,0.5c0,5.8,0,11.5,0,17.6c-4.7,0-9.1,0-13.4,0c-0.1-0.2-0.3-0.4-0.4-0.5
	c0-5.4,0-10.8,0-16.1c-3.9-0.7-8.3,1.2-9.8,5c-1.4,3.5-1.7,7.5-2.5,11.5c-3.2,0-6.9,0-11.1,0c0-5.6,0-11.2,0-16.9
	c-5.1,0-9.4,1-13,4.2c-3.8,3.4-3.3,8-3.2,12.9c-4.3,0-8.4,0-12.6,0c-0.1-0.2-0.1-0.4-0.2-0.6c0-5.4,0-10.8,0-16.4
	c-5.1-0.3-9.9-0.6-14.5,1.6c-4.6,2.3-7,6-7.1,11.1c-0.1,3.7,0,7.4,0,11.5c51,0,101.9,0,153.2,0c0,3.7,0.1,7-0.1,10.3
	c0,0.7-1,1.5-1.8,1.9c-8.1,4.7-16.3,9.4-24.5,14.1C361.7,303.6,361.5,303.8,361.4,304.1z M214,256.1c0-0.2,0-0.4,0-0.6
	c-5.9-5.9-19.3-5.9-25.5,0c0,0.1-0.1,0.1-0.1,0.2c0,0.2,0.1,0.3,0.1,0.5c0,6.9,0,13.8,0,21.1c4.5,0,8.6,0,12.6,0c4.2,0,8.3,0,12.9,0
	C214,269.9,214,263,214,256.1z M115.9,352.8c-3.5-7.6-7-15.1-10.6-22.7c-1.5-3.1-3-6.4-5-9.2c-1.2-1.6-3.4-2.5-5.2-3.8
	c-0.2,0.3-0.4,0.5-0.6,0.8c5.4,24.3,15.1,46.4,33.7,64.1c-6.6,2.7-12.8,5.2-19,7.8c-0.3,0.2-0.6,0.5-1,0.7c0.3,0.1,0.6,0.1,0.9,0.2
	c3.8,0,7.6,0,11.5,0c0.6-0.1,1.3-0.2,1.9-0.2c0.6-0.2,1.3-0.4,1.9-0.6c24.9-9.5,49.8-18.9,74.6-28.5c1.9-0.7,3.5-0.8,5.4,0
	c10.7,4.5,21.5,8.8,32.2,13.2c5,2,10,4.1,15.1,6.1c0.2-0.7,0.3-1,0.3-1.4c0-15.7,0.2-31.4-0.1-47.1c-0.1-5.6-2.3-10.5-7-14.1
	c-1.7-1.3-3.2-1.6-5.2-0.6c-5,2.4-8.7,6.1-9.9,11.5c-0.9,3.9-1,8.1-1.1,12.2c-0.2,6.7,0,13.5,0,20.8c-4.9-2.1-9.4-4.1-13.9-6
	c-1.6-0.6-2.1-1.6-2.1-3.2c0.1-5.8,0.2-11.6-0.1-17.4c-0.3-6.8-2.7-12.8-8.5-17c-2.5-1.8-4.6-1.7-7,0.1c-5.8,4.2-8.2,10.2-8.5,17
	c-0.3,6-0.2,12-0.1,18c0,1.8-0.6,2.7-2.3,3.3c-4.2,1.6-8.3,3.3-12.4,5c-0.2,0-0.5,0-0.7-0.1c-0.1-9.6-0.1-19.2-0.4-28.8
	c-0.2-5.7-2.9-10.3-7.7-13.7c-4.9-3.4-7.4-3-11.1,1.7c-3,3.7-4.2,8.1-4.2,12.8c-0.1,12.6-0.1,25.1-0.2,37.7
	c-0.1,0.1-0.2,0.3-0.3,0.4c-4.3,1.8-8.5,3.5-13,5.4c-0.1-1.4-0.2-2.5-0.2-3.5c0-0.2,0-0.4,0-0.6c0-12.7,0.2-25.5,0-38.2
	c-0.1-7.4-3.1-13.4-9.9-17.1c-2.5-1.4-4.5-1-5.6,1.5c-1.3,2.9-2.9,6-3.1,9c-0.6,8-0.5,16.1-0.7,24.2
	C116.3,352.7,116.1,352.7,115.9,352.8z M130,167c-0.1-0.1-0.2-0.1-0.3-0.2c2.9-7.8,5.7-15.5,8.4-22.8c0.3-0.2,0-0.1-0.2,0.1
	c-10.9,11.4-19.7,24.2-25.4,39c-0.2,0.5-0.4,1.1-0.5,1.6c-0.8,5.6-1.6,11.3-2.4,16.9c-0.8,5.7-0.7,5.6-6.5,6.4
	c-1.2,0.2-3.2,0.6-3.4,1.4c-0.9,2.6-1.2,5.4-1.8,8.6c5.5-0.5,10.3-1,15.1-1.4c11.9-0.9,23.9-1.7,35.8-2.4c8.8-0.5,17.6-1,26.5-1.1
	c22.2-0.1,44.4,0.2,66.6,0.3c0.2-0.1,0.4-0.2,0.6-0.3c-0.2-3.2-0.5-6.3-0.7-9.9c5.3,0.2,10.1,0.5,15.4,0.7c-0.6-4-1.4-7.6-1.7-11.1
	c-0.7-7.9-4.1-14.3-9.7-19.8c-0.6-0.6-1.1-1.6-1.3-2.4c-4-14.9-8.8-29.3-18.8-41.6c19.4,6.4,34.8,19.2,50.3,31.8
	c-0.3-0.9-0.7-1.7-1.2-2.5c-11.1-16.6-25.8-28.5-44.5-35.6c-9.8-3.7-19.9-5.4-30.2-4.4c-9.8,0.9-18.2,5.3-25.4,11.9
	c-8.6,7.8-14.8,17.4-20.7,27.1c-0.5,0.7-0.9,1.5-1.4,2.2c1-9.8,4.7-18.6,9.1-27.2c-6.6,4.3-12.5,9.4-17.3,15.4
	C139.3,154,134.7,160.6,130,167z M251.6,396.8c-0.6-0.4-0.9-0.6-1.3-0.7c-15.7-6.4-31.3-12.9-47-19.2c-1-0.4-2.4-0.2-3.5,0.2
	c-12.4,5-24.7,10-37,15.1c-2.9,1.2-5.8,2.5-9,3.8C179,410.9,225.3,411.3,251.6,396.8z M250.9,304.5c0.1-0.2,0.1-0.3,0.2-0.5
	c-4.4-2.6-8.7-5.3-13.1-7.8c-1.4-0.8-3.2-1.3-4.8-1.3c-46.4-0.1-92.7-0.1-139.1-0.1c-1,0-2,0.1-3.4,0.2c0.5,3.4,0.9,6.4,1.3,9.5
	C145.2,304.5,198.1,304.5,250.9,304.5z M145.3,276.9c7.5,0,14.8,0,22.1,0c0-4.2,0.2-8.1-0.1-11.9c-0.3-5-3-8.5-7.5-10.6
	c-4.7-2.2-9.5-1.9-14.6-1.6C145.3,260.9,145.3,268.8,145.3,276.9z M131.5,277.2c0-4.1,0-7.9,0-11.7c0-8.4-7.9-14.5-16.6-12.4
	c0,7.4,0,14.9,0.1,22.4c0,0.6,1.1,1.6,1.7,1.6C121.4,277.2,126.2,277.2,131.5,277.2z M271.4,178c-5.2,9.1-1,18.1-1,27.1
	c4.2,0.4,8,0.8,12.7,1.2c-1.8-7.5-3.3-14.5-5.3-21.4C276.9,181.9,274.6,179.6,271.4,178z M91.2,252.8c0,8.2,0,16.2,0,24.4
	c3.5,0,6.7,0.1,9.9-0.1c0.6,0,1.7-1,1.7-1.6c0-4.7,0.1-9.5-0.5-14.2C101.6,256,96.7,252.5,91.2,252.8z M301.7,208.2
	c-2.8-7.9-5.4-15-8-22.2c-0.4,0-0.8,0.1-1.2,0.1c-0.3,1.1-1,2.2-0.9,3.2c0.4,5.5,1,10.9,1.6,16.4c0.1,0.6,0.8,1.5,1.4,1.7
	C296.7,207.8,298.7,207.9,301.7,208.2z'
        />
        <path
          fill='#031F46'
          d='M210.9,202.1c-6.1,0-12,0-17.8,0c-0.2-0.2-0.3-0.4-0.3-0.6c0.5-8.7,0.6-17.4,1.8-26c0.5-3.4,3.4-6.6,5.4-9.7
	c1-1.6,2.4-1.3,3.8,0c4.7,4.5,6.1,10.4,6.4,16.6c0.2,4.7,0.1,9.5,0.2,14.3C210.4,198.5,210.7,200.2,210.9,202.1z'
        />
        <path
          fill='#032046'
          d='M221,202.8c-2.2-22.8-4.4-45.1-6.5-67.6c0.9,1.3,2,2.5,2.8,3.9c4.9,8.2,7.4,17.4,9,26.6
	c1.8,10.1,2.8,20.3,4.1,30.5c0.2,1.6,0.4,3.2,0.6,4.8c0.2,1.7-0.6,2.5-2.3,2.4C225.9,203.2,223.2,203,221,202.8z'
        />
        <path
          fill='#032247'
          d='M172.8,203.6c2.2-22.9,3.1-45.4,14.9-66.3c-2,22.2-3.9,43.6-5.8,65.4C179.3,203,176.4,203.3,172.8,203.6z'
        />
        <path
          fill='#032046'
          d='M161.4,203.5c-5.1,0-9.6,0-14.4,0c0.6-3.6,1.1-7.2,1.8-10.8c0.8-4,1.4-8.1,2.8-11.9c1-2.7,3-5,4.7-7.4
	c1.5-2,3.3-1.6,4.2,0.5c1.1,2.5,2.4,5.2,2.4,7.7C162.7,188.7,161.9,195.8,161.4,203.5z'
        />
        <path
          fill='#032247'
          d='M132.9,177.6c0.7,3.1,1.9,5.7,1.9,8.3c-0.1,5.9-0.7,11.8-1.2,17.6c0,0.6-0.7,1.5-1.2,1.6
	c-3.8,0.5-7.6,0.9-11.9,1.4C123,196,121.7,184.7,132.9,177.6z'
        />
      </svg>
    </>
  );
};

export default IconGBGLogo;
