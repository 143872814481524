import { Tooltip } from '@mui/material';
import { truncateString } from '@utils/misc';
import React from 'react';
import _ from 'lodash';
import MenuButton from '@components/elements/MenuButton';
import EditIcon from '@as_core/icons/edit.icon';
import TrashIcon from '@as_core/icons/trash.icon';
import { CouponT } from '@subApps/coupons/coupons.types';

export type CouponColT = {
  value: string,
  label: string,
  key: string,
  type: string,
  width: number
}

export const ColDef: CouponColT[] = [
    { value: 'edit', label: '', key: '', type: 'computed', width: 40 },
    { value: 'system', label: 'System', key: 'system', type: 'string', width: 80 },
    { value: 'code', label: 'Code', key: 'code', type: 'string', width: 120 },
    { value: 'product', label: 'Product', key: 'product', type: 'string', width: 150 },
    { value: 'days', label: 'Days', key: 'days', type: 'number', width: 60 },
    { value: 'allowedInvites', label: 'Invitees', key: 'allowedInvites', type: 'number', width: 60 },
    { value: 'allowedRepositories', label: 'Repos', key: 'allowedRepositories', type: 'number', width: 60 },
    { value: 'created', label: 'Created', key: 'created', type: 'date', width: 100 },
    { value: 'expires', label: 'Expires', key: 'expires', type: 'date', width: 100 },
    { value: 'delete', label: '', key: '', type: 'computed', width: 40 },
];

export const getStringHover = (text: string, width: number) => {
  const characters = Math.floor(width/4);
  if (text.length < characters) return text;
  return(
    <Tooltip title={text}>
      { truncateString(text, characters)}
    </Tooltip>
  )
}


// Return the display value for the column from the Coupon
export const getDisplayCouponValue = (col: CouponColT, coupon:CouponT, handleClick: (v:string, i:string) => void) => {
  if (col.value === 'edit') {
    return(
      <MenuButton
        onClick={() => handleClick('edit', coupon?.id)}
        icon={<EditIcon />}
        text={'Update Coupon'}
        tooltipPlacement={'bottom'}
      />
    )
  } else if (col.value === 'delete') {
    return(
      <MenuButton
        onClick={() => handleClick('delete', coupon?.id)}
        icon={<TrashIcon />}
        text={'Delete Coupon'}
        tooltipPlacement={'bottom'}
      />
    )
  } else {
    const value = _.get(coupon, col.key, '');
    if (value === '') return '';
    if (col.type === 'date') {
      const utcDate = new Date(value)
      return utcDate.toLocaleDateString();
    } else {
      return getStringHover(value, col.width);
    }
  }
}

export const getCouponTableRows = (coupons: any[], handleEditClick: (t:string, u:string)=>void) => {
  let rows = [];
  for (const coupon of coupons) {
    let row = {};
    for (const col of ColDef) {
      row[col.value] = getDisplayCouponValue(col, coupon, handleEditClick);
    }
    rows.push(row);
  }
  return rows;
};
