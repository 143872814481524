import { StyleContext } from '@theme/AppStyles';
import { useContext } from 'react';

const BugsActiveIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;

  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={size}
      height={size}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <path fill={color} d="M94.3,323.1c-40.1,10.6-69.7,47.1-69.7,90.5v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7
	c0-30.2,21-55.6,49.2-62.3v-13.5C100.2,333,97.2,328.1,94.3,323.1z"/>
      <path fill={color} d="M70,260.9H14.8c-8.2,0-14.8,6.6-14.8,14.8c0,8.2,6.6,14.8,14.8,14.8H79C75.4,280.9,72.4,271,70,260.9z"/>
      <circle stroke={color} strokeWidth={15} cx="302.3" cy="208.1" r="196.9"/>
      <path fill={color} d="M64,208.1c0-11.2,0.8-22.1,2.3-32.9c-7.6-10.5-12.1-23.5-12.1-37.4v-19.7c0-8.2-6.6-14.8-14.8-14.8
	c-8.2,0-14.8,6.6-14.8,14.8v19.7c0,31.5,15.6,59.3,39.5,76.3C64,212.1,64,210.1,64,208.1z"/>
      <path fill={colorHighlight} d="M353.7,434.1c-22.5,29.4-57.9,48.4-97.8,48.4c-62.8,0-114.8-47.3-122.2-108.2c-11.3-11.1-21.5-23.4-30.4-36.6
	v21.7c0,84.2,68.5,152.6,152.6,152.6c63,0,117.3-38.5,140.5-93.2C382.9,425.3,368.6,430.4,353.7,434.1z"/>
      <path fill={color} d="M450.3,383.6c4.8,9,7.5,19.2,7.5,30v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7c0-19-5.7-36.8-15.5-51.5
	C465.2,369.7,458,376.9,450.3,383.6z"/>
      <path fill={colorHighlight} d="M302.3,208.1l136.3,90.6c-30.3,45.6-81.6,73.1-136.3,73.1c-89.8,0-163.7-73.9-163.7-163.7
	c0-89.8,73.9-163.7,163.6-163.7L302.3,208.1z"/>
    </svg>
  );
};

export default BugsActiveIcon;
