import {CouponCreateT, CouponUpdateT, ResponseT } from './coupons.types';
import axios, { AxiosRequestConfig } from 'axios';
import { Coupons } from '@utils/api';
import { handleError } from '@utils/misc';
import useCognito from "@as_core/account/useCognito";

axios.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response:', JSON.stringify(response, null, 2))
  return response
})

const useCoupons = () => {
  const { getToken } = useCognito();

  const getCoupon = async (code: string): Promise<ResponseT> => {
    const token = getToken();
    const params = { code: code}
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Coupons.get(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('getBugReports ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getAllCoupons = async (): Promise<ResponseT> => {
    const token = getToken();
    let resp;

    try {
      resp = await Coupons.getAll(token);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('getBugReports ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const createCoupon = async (params: CouponCreateT): Promise<ResponseT> => {
    const token = getToken();
    let resp;

    try {
      resp = await Coupons.create(token, params);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('createCoupon ERRORS', resp.data.errors);
    }
    return resp?.data?.data;
  };

  const updateCoupon = async (params: CouponUpdateT): Promise<ResponseT> => {
    const token = getToken();
    let resp;

    try {
      resp = await Coupons.update(token, params);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('updateCoupon ERRORS', resp.data.errors);
    }
    return resp?.data?.data;
  };

  const deleteCoupon = async (id: string): Promise<ResponseT> => {
    const token = getToken();
    const params = {'id': id}
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Coupons.delete(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('deleteCoupon ERRORS', resp.data.errors);
    }
    return resp?.data?.data;
  };

  return {
    getCoupon,
    getAllCoupons,
    createCoupon,
    updateCoupon,
    deleteCoupon
  };
}

export default useCoupons;