import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const EditIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M18.4283 12C18.0338 12 17.714 12.3198 17.714 12.7143V19.8571C17.714
          20.2515 17.3943 20.5713 16.9998 20.5713H4.14283C3.74833 20.5713 3.42854 20.2515
          3.42854 19.8571V5.57154C3.42854 5.17704 3.74833 4.85725 4.14283 4.85725H12.7141C13.1086
          4.85725 13.4284 4.53746 13.4284 4.14296C13.4284 3.74846 13.1086 3.42871 12.7141
          3.42871H4.14283C2.95938 3.42871 2 4.38809 2 5.57154V19.8571C2 21.0405 2.95938
          21.9999 4.14283 21.9999H16.9998C18.1833 21.9999 19.1426 21.0405 19.1426
          19.8571V12.7143C19.1426 12.3198 18.8228 12 18.4283 12Z" fill={color}/>
          <path d="M21.1994 2.80098C20.6866 2.28804 19.991 1.99993 19.2656 2.00001C18.5399
          1.99792 17.8435 2.28653 17.3321 2.80144L7.92361 12.2091C7.84556 12.2878 7.78667
          12.3834 7.75147 12.4884L6.32294 16.7741C6.19826 17.1483 6.40061 17.5528 6.7749
          17.6774C6.84751 17.7016 6.92356 17.714 7.00006 17.7141C7.07673 17.7139 7.15295
          17.7016 7.22577 17.6777L11.5114 16.2491C11.6167 16.214 11.7123 16.1548 11.7907
          16.0763L21.1991 6.66782C22.267 5.60009 22.2671 3.86883 21.1994 2.80098ZM20.1892
          5.65851L10.9036 14.9441L8.12932 15.8705L9.05287 13.0998L18.342 3.81426C18.8527
          3.30458 19.6799 3.30542 20.1896 3.8161C20.433 4.06006 20.5702 4.39031 20.5713
          4.73496C20.5722 5.08146 20.4346 5.41393 20.1892 5.65851Z" fill={color}/>
        </g>
      </svg>
    </>
  )
}

export default EditIcon;