import { StyleContext } from '@theme/AppStyles';
import { useContext } from 'react';

const BugsDeferredIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;

  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={size}
      height={size}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <path fill={color} d="M103.4,321.2c-44.6,7.1-78.8,45.8-78.8,92.4v19.7c0,8.2,6.6,14.8,14.8,14.8s14.8-6.6,14.8-14.8v-19.7c0-30.2,21-55.6,49.2-62.3,0,0,0-30.1,0-30.1Z"/>
      <path fill={color} d="M103.4,260.9H14.8c-8.2,0-14.8,6.6-14.8,14.8s6.6,14.8,14.8,14.8h88.6v-29.6Z"/>
      <path fill={color} d="M103.4,200.1c-28.2-6.7-49.2-32.1-49.2-62.3v-19.7c0-8.2-6.6-14.8-14.8-14.8s-14.8,6.6-14.8,14.8v19.7c0,46.6,34.2,85.3,78.8,92.4,0,0,0-30.1,0-30.1Z"/>
      <path fill={colorHighlight} d="M324.5,134.7c-103.6,0-187.5,83.9-187.5,187.5s83.9,187.5,187.5,187.5,187.5-83.9,187.5-187.5-83.9-187.5-187.5-187.5ZM442.9,332.9l-116.7,116.7c-2.8,2.8-6.4,4.2-10.1,4.2s-7.3-1.4-10.1-4.2c-5.6-5.6-5.6-14.6,0-20.2l93.1-93.1h-183c-7.9,0-14.3-6.4-14.3-14.3s6.4-14.3,14.3-14.3h183l-93.1-93.1c-5.6-5.6-5.6-14.6,0-20.2,5.6-5.6,14.6-5.6,20.2,0l116.7,116.7c3,3,4.3,6.9,4.1,10.8.2,3.9-1.2,7.8-4.1,10.8Z"/>
      <path fill={color} d="M172.3,108.3c0-19,15.5-34.5,34.5-34.5h98.5c19,0,34.5,15.5,34.5,34.5v7.8c10,1.2,19.9,3,29.4,5.6v-13.3c0-31.1-22.3-57-51.7-62.8l11-24.7c3.3-7.5,0-16.2-7.5-19.5s-16.2,0-19.5,7.5l-15.8,35.5h-59.6l-15.7-35.5c-3.3-7.5-12-10.8-19.5-7.5s-10.8,12-7.5,19.5l11,24.7c-29.4,5.8-51.7,31.7-51.7,62.8v54.2h29.6v-54.2Z"/>
      <path fill={colorHighlight} d="M109.5,321.2c0-34.5,8.5-67,23.4-95.5v-33.7h22c8.7-10.8,18.4-20.7,29-29.5h-55.9c-13.6,0-24.6,11-24.6,24.6v172.3c0,76.3,56.3,139.6,129.4,150.8-72.6-32-123.3-104.6-123.3-189Z"/>
      <path fill={color} d="M472.9,187.9c9.2-14.5,14.6-31.7,14.6-50.1v-19.7c0-8.2-6.6-14.8-14.8-14.8s-14.8,6.6-14.8,14.8v19.7c0,10.2-2.4,19.8-6.6,28.3,7.7,6.7,15,14,21.6,21.8Z"/>
    </svg>
  );
};

export default BugsDeferredIcon;
