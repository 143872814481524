import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import RadioButton from '@components/elements/RadioButton';
import BugsTable from 'src/subApps/bugs/components/BugsTable';
import useBugReports from '../../../as_core/bugsReporting/bugs.api';
import { CircularProgress } from '@mui/material';

const filterTypes = [
  { value: 'all', label: 'All' },
  { value: 'open', label: 'Open' },
  { value: 'not_acknowledged', label: 'Not Acknowledged' },
  { value: 'not_assigned', label: 'Not Assigned' },
  { value: 'in_process', label: 'In Process' },
  { value: 'closed', label: 'Resolved' },
];

const BugAdministration = () => {
  const { getBugReports } = useBugReports();
  const [bugReports, setBugReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState('open');

  useEffect(() => {
    const params = { admin: true, system: '3RnD', bug_type: filter };

    getBugReports(params)
      .then((response) => {
        setBugReports(response?.data || []);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [filter]);

  const handleFilterChange = (value) => setFilter(value);

  return (
    <Container>
      <FilterSelector>
        {filterTypes.map((item, i) => {
          return (
            <FilterItem key={i}>
              <RadioButton
                key={i}
                id={i}
                value={item.value}
                checked={filter === item.value}
                onClick={() => handleFilterChange(item.value)}
              >
                {item.label}
              </RadioButton>
            </FilterItem>
          );
        })}
      </FilterSelector>
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <BugsList>
          <BugsTable bugReports={bugReports} />
        </BugsList>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const FilterSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FilterItem = styled.div`
  display: flex;
  font-size: 12px;
  margin-right: 10px;
`;

const BugsList = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;

export default BugAdministration;
