import React, { useContext, ReactNode, useEffect, useState, useRef } from 'react';
import { UserContext } from '@contexts/UserContext';
import * as AWSCognitoIdentity from 'amazon-cognito-identity-js';
import useCognito from '@as_core/account/useCognito';
import { useNavigate } from 'react-router-dom';
import LogoutAlertDialog from '@as_core/account/forms/LogoutAlertDialog';

/*
  Primary component to handle the user authentication and inactivity states
  Parameters:
    CHECK_AUTHENTICATION_PERIOD: time interval for the app to recheck inactivity and time since authenticated
 */
import config from '@app_config/cognito.json';
const CHECK_AUTHENTICATION_PERIOD:number = 60 * 1000; // in milliseconds = 1 minute

interface AuthenticatorProps {
  children: ReactNode;
}

const debug: boolean = false;
const AuthWrapper: React.FC<AuthenticatorProps> = ({ children }) => {
  const { logout, isTokenExpired, getAuthRoles } = useCognito();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);
  // console.log('AuthWrapper | pathname', location.pathname);
  debug && console.log('AuthWrapper | .isLoading', user.isLoading, '.isAuthenticated', user.isAuthenticated,
    '.isRegistered', user.isRegistered, 'alertOpen', alertOpen);

  // need to use references for functions so do not have stale states
  const isAuthenticatedRef = useRef(user?.isAuthenticated);
  const userSessionRef = useRef(user?.authSession);
  isAuthenticatedRef.current = user.isAuthenticated;
  userSessionRef.current = user.authSession;

  const clearUser = () => {
    setUser((prev) => ({
      ...prev, isLoading: false, isAuthenticated: false,
      authId: null, authEmail: '', authSession: null,
      userRoles: [],
      isRegistered: false, regInfo: null
    }));
  }

  // action to take when user inactive or too long since authentication - dhr
  const logoutActionEmail = (authEmail: string, showAlert:boolean) => {
    console.log('logout Action by email triggered | authEmail', authEmail, new Date().toLocaleString());
    if (showAlert) setAlertOpen(true);
    if (authEmail) logout(authEmail);
    clearUser();
    navigate('/user/login');
  };

  const logoutActionSession = (session: AWSCognitoIdentity.CognitoUserSession, showAlert:boolean) => {
    console.log('logout Action by session triggered | session', session, new Date().toLocaleString());
    const authEmail = session?.getIdToken().payload?.email;
    logoutActionEmail(authEmail, showAlert);
  };

  const checkAuthenticationStatus = () => {
    const timestamp = new Date().getTime()/1000;
    debug && console.log('checkActiveAuthentication isAuthenticated: ', isAuthenticatedRef.current,
      ' isTokenExpired: ', isTokenExpired(userSessionRef.current), ' current date:', new Date().toLocaleString(),
      ' current datetime: ', Math.floor(timestamp));
    if (isAuthenticatedRef.current) {
      if (isTokenExpired(userSessionRef.current)) {
        debug && console.log('tokenExpired --- forcing logout', new Date().toLocaleString());
        logoutActionSession(userSessionRef.current, true);
      }
    }
  };

  // Set the event timer for checking user token not expired;
  useEffect(() => {
    const timerId = setInterval(
      checkAuthenticationStatus, CHECK_AUTHENTICATION_PERIOD
    );
    return () => clearInterval(timerId);
  }, []);

  // If user not authenticated (first time) -- check to see if user has active session using cognito
  // -- needed for the redirects back from Stripe (or other applications)
  useEffect(() => {
    if (!isAuthenticatedRef.current) {
      let cognitoUser = null;
      const poolData = {UserPoolId: config.userPoolId, ClientId: config.clientId};
      let userPool = null;
      try {
        userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
      } catch {
        console.log('getUserPool error');
      }
      if (userPool !== null) {
        try {
          cognitoUser = userPool.getCurrentUser();
        } catch {
          console.error('getCurrentUser error');
        }
      }
      debug && console.log('cognitoUser:', cognitoUser);
      if (cognitoUser !== null) {
        // only reload if not longer than authentication timeout
        cognitoUser.getSession(function (err: { message: any; }, session: AWSCognitoIdentity.CognitoUserSession) {
          if (err) {
            console.error('cognitoUser.getSession error', err);
            clearUser();
            return;
          } else {
            debug && console.log('Reloading user cognito session for user', session);
            if (isTokenExpired(session)) {
              console.log('Expiring user authentication state --- tokenExpired ...', session, new Date().toLocaleString());
              clearUser();
              return;
            } else {
              const userId = session.getIdToken().payload;
              const dataKey = 'storedUserRegData_' + userId?.sub;
              debug && console.log(`AuthWrapper | locating user data using key "${dataKey}"`);
              let userReg = {};
              if (localStorage.getItem(dataKey) !== null) {
                const storedUserData = localStorage.getItem(dataKey);
                userReg = JSON.parse(storedUserData);
                debug && console.log(`AuthWrapper | user data read from local storage key "${dataKey}"`, userReg);
              } else {
                console.error('User authenticated through session, but user registration data is not stored');
                navigate('/user/register');
              }
              setUser((prev) => ({
                ...prev,
                isLoading: false,
                isAuthenticated: true,
                authId: userId['cognito:username'],
                authEmail: userId.email,
                authSession: session,
                authRoles: getAuthRoles(session),
                isRegistered: (Object.keys(userReg).length > 0), regInfo: userReg
              }));
            }
          }
        });
      } else {
        setUser((prev) => ({
          ...prev,
          isLoading: false
        }));
      }
    } else {
      setUser((prev) => ({
        ...prev,
        isLoading: false
      }));
    }
  }, [user.isLoading]);

  return <>
    {children}
    <LogoutAlertDialog
      alertOpen={alertOpen}
      closeAlert={()=>setAlertOpen(false)}
    />
  </>;
};

export default AuthWrapper;
