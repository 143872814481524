import { memo } from 'react';
import TextButtonBasic from './TextButtonBasic';
import TextButtonToolTip from './TextButtonToolTip';

interface TextButtonPropsT {
  tooltip?: string;
  tooltipPlacement?: any;
  text: string;
  icon?: any;
  onClick?: any;
  isActive?: boolean;
  isWarning?: boolean;
  isDisabled?: boolean;
  iconRight?: boolean;
  margin?: string;
  fontSize?: number;
  width?: number;
  height?: number;
  isLoading?: boolean;
}

const TextButton = (props: TextButtonPropsT) => {
  if (props?.tooltip) return <TextButtonToolTip {...props} />;
  return <TextButtonBasic {...props} />;
};
export default memo(TextButton);
