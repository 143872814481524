import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import { useParams } from 'react-router-dom';
import { UserContext } from '@contexts/UserContext';
import { MenuContext } from '@contexts/MenuContext';
import CenteredMessages, { MessageItem } from '@as_core/elements/CenteredMessages';
import { CircularProgress } from '@mui/material';
import useCoupons from './useCoupons';
import SystemSelector from '../shared/SystemSelector';
import CouponsTable from './components/CouponsTable';
import { CouponT } from '@subApps/coupons/coupons.types';

const REQUIRED_ROLES = ['aseda-admin'];

export function hasRequiredRole(userRoles: string[], requiredRoles: string[]) {
  return userRoles.some((r) => requiredRoles.includes(r));
}

const debug = false;
const PgBugsAdmin = () => {
  const { action} = useParams();
  const [ system, setSystem] = useState<string>('3RnD');
  const [ couponsUpdated, setCouponsUpdated ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ coupons, setCoupons] = useState<CouponT[]>([]);
  const [ systemCounts, setSystemCounts] = useState<{[key: string]: number }>(null);
  const { user, setUser } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const { getAllCoupons } = useCoupons();
  debug && console.log('PgCouponsAdmin | action:', action, ' user', user);

  if (user.subApp !== 'coupons') {
    setUser((prev) => ({
      ...prev, subApp: 'coupons'
    }));
  }
  if (activeMenuId !== 'coupons') setActiveMenuId('coupons');

  // get bug counts and updated when flagged by component
  useEffect(()=> {
    setIsLoading(true);
    setCouponsUpdated(false);
    getAllCoupons().then((resp) => {
      debug && console.log('counts:', resp);
      setCoupons(resp.data);
      setIsLoading(false);
    })
  },[couponsUpdated]);

  return (
    <PageContainer title={'Coupon Administration'}>
      <SystemSelector
        systemCounts={systemCounts}
        system={system}
        setSystem={setSystem}
      />
      <Container>
        { hasRequiredRole(user?.authRoles, REQUIRED_ROLES) ?
          <CouponsListContainer>
            {isLoading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <CouponsList>
                <CouponsTable
                  coupons={coupons}
                  refreshList={() => setCouponsUpdated(true)}
                  setUpdated={() => setCouponsUpdated(true)}
                />
              </CouponsList>
            )}
          </CouponsListContainer>
          :
            <CenteredMessages messages={[
              <MessageItem color={'accent'}>Not Authorized to Access this Tool/Functionality</MessageItem>,
              <MessageItem>If you should be authorized, please submit a bug report.</MessageItem>
            ]}/>
        }
      </Container>
    </PageContainer>
  );
};

export default PgBugsAdmin;

const Container = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  margin-top: 24px;
`;

const CouponsListContainer = styled.div`
  width: 100%;
`;

const CouponsList = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;
