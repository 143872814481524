import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';
import {
  BasicTextInput,
  ActionButtons,
  FormLabel,
  Row,
} from '@as_core/forms/FormStyles';
import Button from '@as_core/account/fields/Button';
import styled from 'styled-components/macro';
import SingleSelect from '@components/elements/selects/SingleSelect';
import { FlexItem } from '@as_core/elements/flexStyles';
import { CouponCreateT } from '@subApps/coupons/coupons.types';

type PropsT = {
  handleCreate?: (updates: any) => void;
  handleCancel?: () => void;
}

const defaultCoupon: CouponCreateT = {
  system: '3rnd',
  code: '',
  product: 'pilot',
  expires: '',
  days: 60,
  allowedInvites: 0,
  allowedRepositories: 2
}

const debug = false;
export const CreateCoupon = (props:PropsT) => {
  const { handleCreate} = props;
  const [newCoupon, setNewCoupon] = useState(defaultCoupon);

  const _handleUpdates = (field:string, value:any) => {
    if (field === 'expired') {
      debug && console.log(field, value);
    }
    setNewCoupon((prev) => ({...prev, [field]: value }));
  }

  const handleCancel = () => {
    debug && console.log('Cancel Clicked', props?.handleCancel);
    props?.handleCancel && props.handleCancel();
  }

  const unsetStrings = Object.values(newCoupon).filter((v) => typeof v === 'string' && v === '');
  const disabled = unsetStrings.length > 0;

  return (
    <Container key={'create-new-coupon'}>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>System:</FormLabel>
        <FlexItem>
          <SingleSelect
            id={'bug_acknowledge'}
            label={'Acknowledged'}
            value={newCoupon['system']}
            options={[
              { value: '3rnd', label: '3RnD' },
              { value: '3rnd-abbvie', label: '3RnD (AbbVie)' },
              { value: 'sciscore', label: 'SciScore' },
            ]}
            onChange={(e) => _handleUpdates('system', e.target.value)}
            width={200}
          />
        </FlexItem>
      </Row>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>Coupon Code:</FormLabel>
        <FlexItem>
          <BasicTextInput
            id='code'
            type='string'
            name='code'
            placeholder='Enter Code ...'
            value={newCoupon['code']}
            onChange={(e) => _handleUpdates('code', e.target.value)}
          />
        </FlexItem>
      </Row>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>Product Code:</FormLabel>
        <FlexItem>
          <SingleSelect
            id={'product_code'}
            label={'Product'}
            value={newCoupon['product']}
            options={[
              { value: 'pilot', label: 'Pilot Users Regular' },
              { value: 'pilot_A', label: 'Pilot Users Academic' },
            ]}
            onChange={(e) => _handleUpdates('product', e.target.value)}
            width={200}
          />
        </FlexItem>
      </Row>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>Expiration Date:</FormLabel>
        <FlexItem width={'200px'} height={'50px'}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
            <DatePicker
              label={'Expiration Date'}
              format={'YYYY-MM-DD'}
              value={newCoupon['expires']}
              onChange={(v) => _handleUpdates('expires', v)}
            />
          </LocalizationProvider>
        </FlexItem>
      </Row>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>Days For Product Use:</FormLabel>
        <FlexItem>
          <BasicTextInput
            id='days'
            type='number'
            name='days'
            placeholder='Enter Days ...'
            value={newCoupon['days']}
            onChange={(e) => _handleUpdates('days', e.target.value)}
          />
        </FlexItem>
      </Row>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>Allow Invites:</FormLabel>
        <FlexItem>
          <BasicTextInput
            id='allowedInvites'
            type='number'
            name='allowedInvites'
            placeholder='Enter Allowed Invites ...'
            value={newCoupon['allowedInvites']}
            onChange={(e) => _handleUpdates('allowedInvites', e.target.value)}
          />
        </FlexItem>
      </Row>
      <Row width='400px' align={'flex-start'}>
        <FormLabel width={150}>Allow Repositories:</FormLabel>
        <FlexItem>
          <BasicTextInput
            id='allowedRepositories'
            type='number'
            name='allowedRepositories'
            placeholder='Enter Allowed Repositories ...'
            value={newCoupon['allowedRepositories']}
            onChange={(e) => _handleUpdates('allowedRepositories', e.target.value)}
          />
        </FlexItem>
      </Row>
      <ActionButtons>
        <Button type='cancel' width={100} label={'Cancel'} onClick={() => handleCancel()} />
        <Button
          type='submit'
          width={100}
          label={'Create Coupon'}
          disabled={disabled}
          onClick={disabled ? ()=>{} : () => handleCreate(newCoupon)}
        />
      </ActionButtons>
    </Container>
  );
};

export default CreateCoupon;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;