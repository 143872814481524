import React from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import PagePanel from '../components/pages/PagePanel';
import AccountDetails from '@as_core/account/AccountDetails';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import MenuButton from '@components/elements/MenuButton';
import EditIcon from '@as_core/icons/edit.icon';
import { useNavigate } from 'react-router-dom';

const debug = false;
const PgUserAccount = () => {
  debug && console.log('PgUserAccount:');

  const navigate = useNavigate();

  return (
    <PageContainer title='User Management'>
      <UserAccountContainer>
        <PagePanel title={'My Registration Details'}>
          <FlexRow>
            <FlexItem>
              <MenuButton
                onClick={() => navigate('/user-registration')}
                tooltipPlacement={'bottom'}
                icon={<EditIcon />}
                text={'Edit Registration'}
              />
            </FlexItem>
            <FlexItem>
              <AccountDetails />
            </FlexItem>
          </FlexRow>
        </PagePanel>
        <PagePanel title={'My Roles'}>
          Add Roles - request information here
        </PagePanel>
      </UserAccountContainer>
    </PageContainer>
  );
};

export default PgUserAccount;

const UserAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: calc(100vw - 65px);
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
`;
