import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import styled from 'styled-components';
import { IGBGTable } from './types';
import { useMemo, useState } from 'react';
import { getGbgVersion } from '@data/dataGBG';
import SearchInput from '@components/elements/inputs/SearchInput';
import ViewIcon from '@components/elements/icons/ViewIcon';
import MenuButton from '@components/controls/MenuButton/MenuButton';
import {
  TextTitle,
  TextData,
  TextDataItalics,
  TextSpace,
} from '@components/elements/TextStyles';
import {
  FlexBanner,
  FlexBannerGroup,
  FlexRow,
  FlexItem,
} from '@components/layout/FlexStyles';
import { useNavigate } from 'react-router-dom';
import RangeSlider from '@components/elements/RangeSlider';

const CustomToolbar = () => {
  return (
    <StyledGridToolbarContainer>
      {/* Turned off <GridToolbarFilterButton placeholder={undefined} />
      <GridToolbarDensitySelector placeholder={undefined} />
      <GridToolbarExport /> */}
    </StyledGridToolbarContainer>
  );
};

const debug = false;
const version = getGbgVersion();

const Preview = (props: any) => {
  const navigate = useNavigate();

  const { id: uuid } = props;
  debug && console.log('Preview | uuid:', uuid);
  return (
    <MenuButton onClick={() => navigate(`/gbg/${uuid}`)} icon={<ViewIcon />} />
  );
};

const GBGTable = (props: IGBGTable) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [rangeFundingValue, setRangeFundingValue] = useState<number>(0);
  const [rangeYears, setRangeYears] = useState<number>(0);
  const { data, defaultColumns } = props;

  const columns: GridColDef[] = defaultColumns.map((item: string) => {
    if (item.toLowerCase() === 'row') {
      return {
        field: item,
        headerName: 'See info',
        renderCell: (props) => Preview({ ...props }),
        sortable: false,
        align: 'center',
      };
    } else {
      return {
        field: item,
        headerName: item,
        sortable: true,
        flex: 1,
      };
    }
  });

  const onChangeValue = (event) => {
    setSearchValue(event.target.value);
  };

  // keeping all rows as search may go on description as well -- even if not shown.
  const localRows = data?.rows;

  const rows = useMemo(() => {
    let localData = [...localRows];
    debug && console.log('TableGBG | localData', localData);

    // only search selected fields
    if (searchValue.length > 2) {
      localData = localRows.filter(
        (row) =>
          row?.Description.includes(searchValue) ||
          row?.Title.includes(searchValue)
      );
    }

    if (rangeFundingValue) {
      localData = localRows.filter(
        (row) => row?.['Max Total Award'] > rangeFundingValue
      );
    }

    if (rangeYears) {
      localData = localRows.filter(
        (row) => row?.['Time Frame (yrs)'] > rangeYears
      );
    }

    return localData;
  }, [searchValue, rangeFundingValue, rangeYears]);

  return (
    <TableContainer>
      <StyledFlexRow>
        <TextTitle color={'accent'}>Available Opportunities</TextTitle>
        <TextSpace />
        <TextDataItalics>({version})</TextDataItalics>
      </StyledFlexRow>
      <FlexBanner>
        <FlexBannerGroup>
          <FlexItem width={'max-content'}>
            <TextData color={'accentSecondary'}>
              (Search Title and Description)
            </TextData>
          </FlexItem>
          <FlexItem width={'400px'}>
            <SearchInput
              value={searchValue}
              onChange={onChangeValue}
              label='Search by any value'
              showLabel={false}
            />
          </FlexItem>
        </FlexBannerGroup>
        <FlexBannerGroup>
          <FlexItem>
            <RangeSlider
              data={{
                value: rangeFundingValue,
                min: 0,
                max: 25000000,
                title: 'Max Funding Amount',
                step: 100000,
                marks: true,
              }}
              handleUpdate={(e, value) => setRangeFundingValue(value)}
            />
          </FlexItem>
        </FlexBannerGroup>
        <FlexBannerGroup>
          <FlexItem>
            <RangeSlider
              data={{
                value: rangeYears,
                min: 0,
                max: 5,
                title: 'Time Frame (years)',
                step: 1,
                marks: true,
              }}
              handleUpdate={(e, value) => setRangeYears(value)}
            />
          </FlexItem>
        </FlexBannerGroup>
        <FlexBannerGroup>
          <FlexItem></FlexItem>
        </FlexBannerGroup>
      </FlexBanner>
      <DataContainer>
        <StyledDataGrid
          disableSelectionOnClick
          // checkboxSelection
          components={{
            Toolbar: CustomToolbar,
          }}
          columns={columns}
          rows={rows}
        />
      </DataContainer>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: calc(100vh - 100px);
  padding: 5px;
  align-items: center;
`;

const DataContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 80px);
  padding: 5px;
  align-items: center;
`;

const StyledDataGrid = styled(DataGrid)`
  min-height: calc(100vh - 220px);
`;

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  place-content: flex-end;

  & button {
    margin-right: 16px;
  }
`;

const StyledFlexRow = styled(FlexRow)`
  justify-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
`;

export default GBGTable;
