import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const UploadIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M20.333 12.8335C20.112 12.8335 19.9 12.9213 19.7438 13.0776C19.5875 13.2339 19.4997
          13.4458 19.4997 13.6668V17.1777C19.499 17.7934 19.2541 18.3838 18.8187 18.8192C18.3833 19.2546
          17.7929 19.4995 17.1772 19.5002H6.82217C6.20641 19.4995 5.61606 19.2546 5.18065 18.8192C4.74524
          18.3838 4.50034 17.7934 4.49967 17.1777V13.6668C4.49967 13.4458 4.41188 13.2339 4.2556
          13.0776C4.09932 12.9213 3.88735 12.8335 3.66634 12.8335C3.44533 12.8335 3.23337 12.9213
          3.07709 13.0776C2.92081 13.2339 2.83301 13.4458 2.83301 13.6668V17.1777C2.83411 18.2353
          3.25475 19.2493 4.00263 19.9972C4.7505 20.7451 5.76452 21.1657 6.82217
          21.1668H17.1772C18.2348 21.1657 19.2488 20.7451 19.9967 19.9972C20.7446 19.2493 21.1652
          18.2353 21.1663 17.1777V13.6668C21.1663 13.4458 21.0785 13.2339 20.9223 13.0776C20.766
          12.9213 20.554 12.8335 20.333 12.8335Z" fill={color}/>
          <path d="M7.58902 9.25582L11.1665 5.67832V16.1667C11.1665 16.3877 11.2543 16.5996 11.4106
          16.7559C11.5669 16.9122 11.7788 17 11.9999 17C12.2209 17 12.4328 16.9122 12.5891
          16.7559C12.7454 16.5996 12.8332 16.3877 12.8332 16.1667V5.67832L16.4107 9.25582C16.5679
          9.40762 16.7784 9.49161 16.9969 9.48971C17.2153 9.48781 17.4244 9.40017 17.5789
          9.24567C17.7334 9.09116 17.821 8.88215 17.8229 8.66365C17.8248 8.44516 17.7408
          8.23465 17.589 8.07748L12.589 3.07748C12.4327 2.92126 12.2208 2.8335 11.9999
          2.8335C11.7789 2.8335 11.567 2.92126 11.4107 3.07748L6.41068 8.07748C6.25889
          8.23465 6.17489 8.44516 6.17679 8.66365C6.17869 8.88215 6.26633 9.09116 6.42084
          9.24567C6.57534 9.40017 6.78435 9.48781 7.00285 9.48971C7.22135 9.49161 7.43185
          9.40762 7.58902 9.25582Z" fill={color}/>
        </g>
      </svg>
    </>
  )
}

export default UploadIcon;