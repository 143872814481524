import React from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import PagePanel from '@components/pages/PagePanel';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgCuration = () => {

  return (
    <PageContainer title={'Page Curation'} >
      <PagePanel title={'Page'} width={'98%'}>
        <Container>page curation</Container>
      </PagePanel>
    </PageContainer>
  );
};

export default PgCuration;

const Container = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-content: center;
  justify-content: center;
`;
