import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ViewIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={color} d="M23,12c0,0-4.9,5-11,5S1,12,1,12s4.9-5,11-5S23,12,23,12z" strokeWidth={1.5} strokeMiterlimit={10}/>
        <circle stroke={color} cx="12" cy="12" r="4.5" strokeMiterlimit={10}/>
        <path fill={color} d="M12,9c-0.3,0-0.5,0-0.6,0.2c0.3,0.3,0.4,0.6,0.4,1.1c0,0.9-0.6,1.5-1.5,1.5c-0.4,0-0.9-0.2-1-0.5C9,11.4,9,11.7,9,12
c0,1.7,1.3,3,3,3s3-1.3,3-3S13.7,9,12,9z"/>
      </svg>
    </>

  )
}

export default ViewIcon;