import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import { useParams } from 'react-router-dom';
import { UserContext } from '@contexts/UserContext';
import { MenuContext } from '@contexts/MenuContext';
import CenteredMessages, {
  MessageItem,
} from '@as_core/elements/CenteredMessages';
import { CircularProgress } from '@mui/material';
import useBugReports from '@as_core/bugsReporting/bugs.api';
import { getTitle, getBugTypeByAction } from './utils';
import SystemSelector from '../shared/SystemSelector';
import BugsTable from './components/BugsTable';

const REQUIRED_ROLES = ['aseda-admin', 'aseda-dev'];

export function hasRequiredRole(userRoles: string[], requiredRoles: string[]) {
  return userRoles.some((r) => requiredRoles.includes(r));
}

const debug = false;
const PgBugsAdmin = () => {
  const { action } = useParams();
  const [system, setSystem] = useState<string>('3RnD');
  const [bugsUpdated, setBugsUpdated] = useState<boolean>(false);
  const [bugCounts, setBugCounts] = useState<{}>({});
  const { user, setUser } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const { getBugReportCounts, getBugReports } = useBugReports();
  const [bugReports, setBugReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  debug && console.log('PgBugsAdmin | action:', action, ' user', user);

  if (user.subApp !== 'bugs') {
    setUser((prev) => ({
      ...prev,
      subApp: 'bugs',
    }));
  }
  const menuId = 'br' + action[0].toUpperCase() + action.slice(1);
  if (activeMenuId !== menuId) setActiveMenuId(menuId);

  // get bug counts and updated when flagged by component
  useEffect(() => {
    const fetchBugCounts = async () => {
      const bugType = getBugTypeByAction(action);
      try {
        const resp = await getBugReportCounts(bugType);
        debug && console.log('counts:', resp);
        setBugCounts(resp);
      } catch (error) {
        console.error(error);
      } finally {
        setBugsUpdated(false);
      }
    };

    const fetchBugReports = async () => {
      const params = {
        admin: true,
        system: system,
        bug_type: getBugTypeByAction(action),
      };
      try {
        const response = await getBugReports(params);
        setBugReports(response?.data || []);
      } catch (error) {
        console.error(error);
      }
    };

    setIsLoading(true);
    Promise.all([fetchBugCounts(), fetchBugReports()]).finally(() =>
      setIsLoading(false)
    );
  }, [action, system, bugsUpdated]);

  return (
    <PageContainer title={getTitle(action)}>
      <SystemSelector
        systemCounts={bugCounts}
        system={system}
        setSystem={setSystem}
      />
      <Container>
        {hasRequiredRole(user?.authRoles, REQUIRED_ROLES) ? (
          <BugsListContainer>
            {isLoading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <BugsList>
                {bugReports.length > 0 ? (
                  <BugsTable
                    action={action}
                    bugReports={bugReports}
                    setUpdated={() => setBugsUpdated(true)}
                  />
                ) : (
                  <BugsInfo>
                    No new bugs - all bugs have been processed
                  </BugsInfo>
                )}
              </BugsList>
            )}
          </BugsListContainer>
        ) : (
          <CenteredMessages
            messages={[
              <MessageItem color={'accent'}>
                Not Authorized to Access this Tool/Functionality
              </MessageItem>,
              <MessageItem>
                If you should be authorized, please submit a bug report.
              </MessageItem>,
            ]}
          />
        )}
      </Container>
    </PageContainer>
  );
};

export default PgBugsAdmin;

const Container = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  margin-top: 24px;
`;

const BugsListContainer = styled.div`
  width: 100%;
`;

const BugsList = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;

const BugsInfo = styled.div`
  text-align: center;
  width: 100%;
`;
