import axios from "axios";
import {APP_CONFIG} from '@app_config/app';

console.log("process.env", process.env);
console.log('APP_CONFIG', APP_CONFIG);

// interfaces
export interface ResponseT<T> {
  take: number;
  skip: number;
  count: number;
  pages: number;
  data?: T;
}

/* Set up the apiClient endpoints set depending on environment parameter REACT_APP_HOST_TYPE */
export const apiClient_local = axios.create({
  baseURL: "https://localhost:8000/cmp",
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiClient_dev = axios.create({
  baseURL: "https://compoundservice.thewarriors3.com/", // <- ENV variable
  //baseURL: 'http://localhost:8000/cmp', // <- ENV variable
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiClient_prd = axios.create({
  // rollback
  baseURL: "https://compoundservice.asedasciences.com/", // <- ENV variable
  headers: {
    "Content-Type": "application/json",
  },
});

// using the REACT_APP_HOST_TYPE that is set in package json scripts

export const apiClient =
  process.env.REACT_APP_HOST_TYPE === "prd" ? apiClient_prd : process.env.REACT_APP_HOST_TYPE === "local" ? apiClient_local : apiClient_dev;

export const apiAppClient =
  process.env.REACT_APP_HOST_TYPE === "prd"
    ? apiClient_prd
    : process.env.REACT_APP_HOST_TYPE === "local"
    ? apiClient_local
    : apiClient_dev;

export const authAppClient = (token) => {
  apiAppClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return apiClient;
};

/* Set up the apiCoreClient endpoints set depending on environment parameter REACT_APP_HOST_TYPE */
export const apiCoreClient_local = axios.create({
  baseURL: 'http://localhost:8000/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiCoreClient_dev = axios.create({
  baseURL: 'https://core-api.thewarriors3.com/', // <- ENV variable
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiCoreClient_prd = axios.create({ // rollback
  baseURL: 'https://core-api.asedasciences.com/', // <- ENV variable
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiCoreClient = process.env.REACT_APP_HOST_TYPE === "prd" ? apiCoreClient_prd :
    process.env.REACT_APP_HOST_TYPE === "local-core" ? apiCoreClient_local : apiCoreClient_dev;

export const authCoreClient = (token) => {
  apiCoreClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiCoreClient;
};

