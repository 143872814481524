import styled from 'styled-components/macro';
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import { Search } from '@mui/icons-material';

interface propsT {
  value: string;
  onChange?: (e) => void;
  showLabel?: boolean;
  label?: string;
}

const SearchInput = (props: propsT) => {
  const { onChange = () => {}, showLabel = true, label, value } = props;
  return (
    <FormControl variant='standard' fullWidth>
      {showLabel ? (
        <InputLabel htmlFor='input-with-icon-adornment'>
          {label ? label : 'Search'}
        </InputLabel>
      ) : null}
      <StyledInput
        id='input-with-icon-adornment'
        placeholder='search'
        value={value}
        onChange={onChange}
        fullWidth
        startAdornment={
          <InputAdornment position='end'>
            <Search />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchInput;

const StyledInput = styled(Input)<{ width?: string }>`
  display: flex;

  &.MuiInputBase-root {
    font-size: 12px;
    padding-left: 5px;
    color: ${(p) => p.theme.palette.textSecondary};
    border: ${(p) => p.theme.borders.thin};
    background-color: ${(p) => p.theme.palette.backgroundSecondary};

    &.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
      border-bottom: 2px solid ${(p) => p.theme.palette.accentSecondary};
    }

    &.MuiInput-root.Mui-focused:after {
      border-bottom: 2px solid ${(p) => p.theme.palette.accentPrimary};
    }
  }
`;
