import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import LogoutIcon from '@mui/icons-material/Login';
import MenuButton from '@components/controls/MenuButton';
import useCognito from '@as_core/account/useCognito';
import { UserContext } from '@contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import AppDialogHeader from '@as_core/account/components/AppDialogHeader';
import {Row, StyledA} from '@as_core/account/forms/FormStyles';
import {userFormFields} from "@as_core/account/forms/config";
import ErrorMessage from "@as_core/account/fields/ErrorMessage";
import {fromDotNotation, renderAccountInputField, isValidField, isFormValid} from "@as_core/account/utils";
import UserAccountPolicies from "@as_core/account/fields/UserAccountPolicies";
import Button from "@as_core/account/fields/Button";
import {StatusMessage, UserForm} from "@as_core/account/forms/SignUp";
import useUserRegistrationInfo from "@utils/useUserRegistrationInfo";
import {APP_CONFIG} from "@app_config/app";

// note -- handle all messages internally until everything is done so that a new component does not get
// created -- and maintain user information
// this is called if a user already has an account in cognito but is not registered

const debug = false;
// TODO -- add the registration page here -- decompose Sign-Up so that it uses same component
const Register = () => {
  const { user, setUser } = useContext(UserContext);
  const [userDataValidationKeys, setUserDataValidationKeys] = useState<string[]>([]);
  const [fieldUpdated, setFieldUpdated] = useState<boolean>(false);
  const [userData, setUserData] = useState(
    { 'address.billing': true, 'email': user.authEmail});
  const [registeringUser, setRegisteringUser] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [clickedOnce, setClickedOnce] = useState<boolean>(false);
  const { logout, getToken } = useCognito();
  const { createUserRegistrationInfo } = useUserRegistrationInfo();
  const navigate = useNavigate();

  debug && console.log('Register | user:', user);
  const handleLogout = () => {
    logout(user?.authEmail);
    navigate('/user/login');
  };

  // set appropriate fields in the userData object
  const handleChange = (key:string, value:string) => {
    setUserData((prev) => ({ ...prev, [key]: value }));
  };

  // capture the user input to update validation state as needed
  const handleBlur = () => {
    if (clickedOnce) {
      const newIsValid = isFormValid('register', userData, userDataValidationKeys, setErrorMessage);
      setIsValid(newIsValid);
      setFieldUpdated(true);
    }
  };

  const handleSubmit = () => {
    if (!clickedOnce) setClickedOnce(true);
    if (isFormValid('register', userData, userDataValidationKeys, setErrorMessage)) {
      setRegisteringUser(true);
      // register user and set their information
      const info = fromDotNotation(userData);
      if (Object.hasOwn(info, 'password')) delete info['password']; // make sure password is not sent
      info['system'] = APP_CONFIG.system;
      debug && console.log('info for creating user', info);
      createUserRegistrationInfo(getToken(), info)
      .then((response) => {
        // @ts-ignore
        if (response?.data.length) {
          const data = response?.data[0];
          // as it is a new user, set to isAppAuthorized and add the zero credits
          setUser((prev) => ({
            ...prev,
            regInfo: data?.user,
            isRegistered: true,
            isAppAuthorized: false,
          }));
          // store locally in case user hits reload -- or for stripe direct / redirect
          // Note that on create the data returned included object id and user key
          const dataKey = 'storedUserRegData_' + user.sub;
          debug && console.log(`SignUp | saving UserReg data to "${dataKey}" | UserReg data:`, data?.user);
          localStorage.setItem(dataKey, JSON.stringify(data?.user));
          navigate('/home');
        } else {
          setRegisteringUser(false);
          console.error('RegistrationException: ', response?.errors);
          setErrorMessage(response?.errors[0]);
        }
      })
      .catch((error) => {
        setRegisteringUser(false);
        console.error('CreateUserRegistrationAPIError: ', error);
        setErrorMessage(error);
      });
    }
  }

  // set validation keys on first creation
  useEffect(() => {
    let userDataKeys = [];
    userFormFields.forEach((group) => {
      group.forEach((field) => {
        if (field.value !== 'password') userDataKeys.push(field.value);
      });
    });
    userDataKeys.push('organization.type');
    setUserDataValidationKeys(userDataKeys);
  }, []);

  return (
    <RegisterContents key={'user-register'}>
      <AppDialogHeader message={'Add User Registration'}/>
      <TwoColumn>
        <AlertMessage>
          <Message>
            You have landed at this page as it appears that you have an authenticated account,
            but no user registration details.
            Please add registration content and submit to continue.
          </Message>
          <Message> To exit this app sign-out:
            <MenuButton
              key={'register_logout'}
              text={'Sign Out of Account'}
              icon={<LogoutIcon />}
              label={'Sign-Out'}
              onClick={handleLogout}
            />
          </Message>
          <Message>
            If you need assistance, please contact us at:</Message>
          <Message>
            <StyledA href={'mailto:support@asedasciences.com'}>AsedaSciences Support</StyledA>
          </Message>
        </AlertMessage>
        <RegisterForm>
          {  registeringUser ?
            <StatusMessage>Registering User Information</StatusMessage>
            :
            <StyledUserForm key={'user-registration-form'}>
              <Row height={'18px'}>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
              </Row>
              {userFormFields.map((row, index) => (
                <Row width={'100%'} key={'row_' + index}>
                  {row.map((info, row_index) =>
                    renderAccountInputField('register', info, `${index}_${row_index}`,
                      userData, handleChange, clickedOnce && !isValidField(info.value, userData),
                      handleBlur)
                  )}
                </Row>
              ))}
              <Row>
                <UserAccountPolicies />
              </Row>
              <Row>
                <Button
                  type={'submit'}
                  label={'Register Account'}
                  onClick={handleSubmit}
                />
              </Row>
              <Row>
                { fieldUpdated && isValid? <></> : null}
              </Row>
            </StyledUserForm>
          }
        </RegisterForm>
      </TwoColumn>
    </RegisterContents>
  );
};

export default Register;

const RegisterContents = styled.div`
  width: max-content;
  height: max-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
  border-radius: 30px;
`;

const TwoColumn = styled.div`
  display: flex;
  flex-direction: row;
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding-right: 10px;
  color: ${(p) => p.theme.palette.textSecondary };
  margin: 5px;
`;

const RegisterForm = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
`;

const AlertMessage = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledUserForm = styled(UserForm)`
  padding: 10px;
`;
