import GBG_current from './GBG/GBG_2022_09_07.json';

const debug = false;

export function getGbgData() {
  debug && console.log('GBG_Current :', GBG_current);
  return GBG_current;
}

export function getGbgDataUuid(uuid:string) {
  const index = GBG_current.rows.findIndex((r) => r.uuid = uuid);
  if (index === -1) return {};
  return GBG_current.rows[index];
}

export function getGbgVersion() {
  return 'vers. 2022-09-07';
}
