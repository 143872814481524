import React from 'react';
import styled from "styled-components/macro";

interface PropsI {
  account?: boolean;
}
const UserAccountPolicies = (props:PropsI) => {
  const { account = false } = props;
  const width = account ? '100%' : '80%';
  return (
    <AccountPoliciesContainer width={width}>
      By registering and using this website, I accept the content of the
      <Link
        href='/documents/AsedaSciences_Website_Terms_of_Use.pdf'
        target="_blank"
        rel="noopener noreferrer"
      >Website Terms of Use</Link>,
      <Link
        href='/documents/AsedaSciences_Additional_Terms_for_Subscription_Services.pdf'
        target="_blank"
        rel="noopener noreferrer"
      >Terms for Subscription Services</Link>,
      <Link
        href='/documents/AsedaSciences_Cookie_Policy.pdf'
        target="_blank"
        rel="noopener noreferrer">Cookie Policy</Link>,
      and
      <Link
        href='/documents/AsedaSciences_Privacy_Policy.pdf'
        target="_blank"
        rel="noopener noreferrer">Privacy Policy</Link>
    </AccountPoliciesContainer>
  );
};

export default UserAccountPolicies;

const AccountPoliciesContainer = styled.div<{width: string}>`
  width: ${(p) => p.width};
  font-size: 10px;
  text-align: center;
  line-height: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
  padding: 10px 0;
`;

const Link = styled.a`
  margin: 0 0 0 3px;
  &:focus {
    outline: none !important;
    border: 0;
  }
`;

