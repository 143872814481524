import { StyleContext } from '@theme/AppStyles';
import { useContext } from 'react';

const BugsNewIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;

  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={size}
      height={size}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <path fill={color} d="M103.4,321.2c-44.6,7.1-78.8,45.8-78.8,92.4v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7
	c0-30.2,21-55.6,49.2-62.3V321.2"/>
      <path fill={color} d="M103.4,260.9H14.8c-8.2,0-14.8,6.6-14.8,14.8c0,8.2,6.6,14.8,14.8,14.8h88.6V260.9z"/>
      <path fill={color} d="M103.4,200.1c-28.2-6.7-49.2-32.1-49.2-62.3v-19.7c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8v19.7
	c0,46.6,34.2,85.3,78.8,92.4V200.1z"/>
      <path fill={color} d="M408.7,321.2v30.1c28.2,6.7,49.2,32.1,49.2,62.3v19.7c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-19.7
	C487.4,367,453.2,328.3,408.7,321.2z"/>
      <path fill={colorHighlight}  d="M321,5.3l22.3,94.6c1.8,7.6,10.5,11.3,17.2,7.2l85.7-51.8c6.3-3.8,13.3,3.6,9.3,9.7l-53.1,80.5
	c-4.5,6.8-0.7,15.9,7.2,17.6l97,21c7.2,1.6,7.2,11.9,0,13.4l-97,21c-7.9,1.7-11.6,10.8-7.2,17.6l53.1,80.5c4,6.1-3,13.5-9.3,9.7
	l-85.7-51.8c-6.7-4-15.4-0.4-17.2,7.2L321,376.2c-1.7,7.1-11.7,7.1-13.4,0l-22.3-94.6c-1.8-7.6-10.5-11.3-17.2-7.2l-85.7,51.8
	c-6.3,3.8-13.3-3.6-9.3-9.7l53.1-80.5c4.5-6.8,0.7-15.9-7.2-17.6l-97-21c-7.2-1.6-7.2-11.9,0-13.4l97-21c7.9-1.7,11.6-10.8,7.2-17.6
	L173.1,65c-4-6.1,3-13.5,9.3-9.7l85.7,51.8c6.7,4,15.4,0.4,17.2-7.2l22.3-94.6C309.3-1.8,319.3-1.8,321,5.3z"/>
      <path fill={color} d="M155.5,70.1c-8,10.7-12.8,23.9-12.8,38.2v35l29.6-6.4v-28.6c0-6.1,1.6-11.9,4.5-16.9L155.5,70.1z"/>
      <path fill={color} d="M226.1,44.3L210.4,8.8c-3.3-7.5-12-10.8-19.5-7.5s-10.8,12-7.5,19.5l4.7,10.6l42.4,42.4h26.9l6.4-29.5H226.1z"/>
      <polygon fill={colorHighlight} stroke={color} strokeWidth={15} points="399.9,199.9 379.1,199.9 379.1,231.3 	"/>
      <path fill={colorHighlight} d="M379.1,299.4v60c0,67.9-55.2,123.1-123.1,123.1s-123.1-55.2-123.1-123.1v-130l-29.5-11.2v141.1
  c0,84.2,68.5,152.6,152.6,152.6s152.6-68.5,152.6-152.6v-40.5L379.1,299.4z"/>
      <path fill={color} d="M497.2,260.9H450l26.7,29.6h20.5c8.2,0,14.8-6.6,14.8-14.8C512,267.5,505.4,260.9,497.2,260.9z"/>
      <path fill={color} d="M482.9,166.3c2.9-9,4.5-18.6,4.5-28.5v-19.7c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8v19.7
	c0,7-1.1,13.8-3.2,20.1L482.9,166.3z"/>
    </svg>
  );
};

export default BugsNewIcon;
