import styled from 'styled-components/macro';
import TextButton from '@as_core/controls/buttons/TextButton';
import CenteredMessages, { MessageItem } from '@as_core/elements/CenteredMessages';

type SystemCountsT = {
  [key: string]: number;
}

type PropsT = {
  systemCounts: SystemCountsT;
  system: string;
  setSystem: (v:string) => void;
}
const SystemSelector = (props:PropsT) => {
  // console.log('bugCounts:', props.bugCounts);
  if (props.systemCounts === null || !Object.keys(props.systemCounts).length) {
    return(<></>)
  }

  if (!Object.hasOwn(props.systemCounts, props.system)) {
    if (!Object.keys(props.systemCounts).length) {
      return (
        <CenteredMessages messages={[
          <MessageItem>All bugs of this class have been processed</MessageItem>
        ]}/>
      )
    } else {
      props.setSystem(Object.keys(props.systemCounts)[0])
    }
  }

  return (
    <SelectorContainer>
      { Object.keys(props.systemCounts).map((s, index) => (
        <TextButton
          key={s + '_' + index}
          text={s + ' (' + props.systemCounts[s] + ')'}
          isActive={s === props.system}
          onClick={() => props.setSystem(s)}
        />
      ))}
    </SelectorContainer>
  )
}

export default SystemSelector;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;